import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { Button, Icon } from 'components/shared';
import { useFilterContext } from 'components/shared/FilterList/V2/context/FilterContext';
import { formatTranslationAsJsx } from 'helpers/stringHelpers';
import { usePlpContext } from '../../context/PlpContext';
import styles from './NoResults.module.scss';

export interface NoResultsProps {}

export const NoResults: FC<NoResultsProps> = () => {
	const { resetFilters } = useFilterContext();
	const { searchQuery } = usePlpContext();
	const { data: translations } = useTranslationQuery();
	const label = isEmpty(searchQuery?.phrase)
		? translations.productList.noResults
		: formatTranslationAsJsx(translations.productList.noResultsFor, {
				0: searchQuery?.phrase,
		  });
	return (
		<div className={styles.content}>
			<h2 className={styles.title}>{label}</h2>

			{searchQuery?.phrase && (
				<div className={styles.searchSuggestions}>
					<h3 className={styles.searchSuggestionsTitle}>
						{translations.productList.searchSuggestionsHeadline}
					</h3>
					<ul>
						<li>{translations.productList.searchSuggestionsText1}</li>
						<li>{translations.productList.searchSuggestionsText2}</li>
						<li>{translations.productList.searchSuggestionsText3}</li>
					</ul>
				</div>
			)}
			{Boolean(searchQuery?.filters?.length) && (
				<Button
					variant="outline"
					size="sm"
					onClick={() => {
						resetFilters();
					}}
				>
					{translations.productList.filters.resetFilters}
					<Icon name="forward" size="sm" />
				</Button>
			)}
		</div>
	);
};
