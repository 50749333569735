import bestseller from './bestseller.svg';
import bestsellerMore from './bestsellerMore.svg';
import { BrandLogos } from './brands';
import comingSoon from './comingSoon.svg';
import hesehusLogo from './hesehusLogo.svg';

export const logos = {
	bestseller,
	bestsellerMore,
	hesehusLogo,
	comingSoon,
	...BrandLogos,
};
