import React, { FC, Suspense } from 'react';
import { OrderHistoryAccordionLevel, OrderHistoryAccordion } from '..';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useOrderHistoryGroupingQuery } from 'api/myOrders';
import { PREDEFINED_ORDERLIST_URL_PARAMS } from 'components/features/MyOrdersPage/constants/OrderListUrlParamKeys';
import { useOrdersPageContext } from 'components/features/MyOrdersPage/context/OrdersPageContext';
import { convertFiltersToFilterRequest } from 'components/features/MyOrdersPage/helpers/convertFiltersToFilterRequests';
import { useFilterContext } from 'components/shared/FilterList/V2/context/FilterContext';
import {
	GroupingRequest,
	GroupingResponse,
	OrderHistoryGroupingRequest,
	OrderHistorySearchGroupingType,
} from 'generated/data-contracts';
import { OrderLineList } from '../../OrderLineList';
import { createHeaderId } from '../OrderHistoryAccordionSummary';
import styles from './OrderHistoryAccordionContent.module.scss';

export interface OrderHistoryAccordionContentProps {
	className?: string;
	level: OrderHistoryAccordionLevel;
	type: OrderHistorySearchGroupingType;
	lastGroupings?: GroupingRequest[];
	grouping: GroupingResponse;
}

const OrderAccordion = ({
	type,
	expandedGroups,
	level,
	grouping,
	className,
}: {
	className?: string;
	level: OrderHistoryAccordionLevel;
	type: OrderHistorySearchGroupingType;
	expandedGroups: GroupingRequest[];
	grouping: GroupingResponse;
}) => {
	const { selectedFilters } = useFilterContext();
	const { status, search, currentGrouping, selectedShipTos } = useOrdersPageContext();
	const nextGroupingType: OrderHistorySearchGroupingType = React.useMemo(() => {
		switch (type) {
			case OrderHistorySearchGroupingType.ShipTo:
				return currentGrouping as OrderHistorySearchGroupingType;
			case OrderHistorySearchGroupingType.DeliveryMonth:
				return OrderHistorySearchGroupingType.Category;
			case OrderHistorySearchGroupingType.Category:
				return OrderHistorySearchGroupingType.DeliveryMonth;
		}
	}, [currentGrouping, type]);

	const selectedShipTo = expandedGroups.find((group) => group.type === OrderHistorySearchGroupingType.ShipTo)?.value;
	const query: OrderHistoryGroupingRequest = {
		groupingType: nextGroupingType,
		shipToIds: selectedShipTo ? [selectedShipTo] : selectedShipTos,
		status,
		phrase: search.search,
		collapseGroups: expandedGroups,
		filters: convertFiltersToFilterRequest(selectedFilters, { excluded: PREDEFINED_ORDERLIST_URL_PARAMS }),
	};
	const deferredQuery = React.useDeferredValue(query);
	const isStale = !isEqual(deferredQuery, query);

	const { data: groupings } = useOrderHistoryGroupingQuery(deferredQuery, level);

	const nextLevel = OrderHistoryAccordionLevel[OrderHistoryAccordionLevel[level + 1]];
	const headerId = createHeaderId(level, grouping);

	const getHeaderHeight = () => {
		const header = document.getElementById(headerId);
		if (!header) return '0px';
		return `${header.getBoundingClientRect().height}px`;
	};

	return (
		<section
			className={classNames(className, styles.container, {
				[styles.isStale]: isStale,
			})}
			style={
				{
					'--summary-height': getHeaderHeight(),
				} as React.CSSProperties
			}
		>
			{groupings?.groupings.map((group) => (
				<OrderHistoryAccordion
					key={group.id}
					type={nextGroupingType}
					lastGroupings={expandedGroups}
					level={nextLevel}
					grouping={group}
				/>
			))}
		</section>
	);
};

export const OrderHistoryAccordionContent: FC<OrderHistoryAccordionContentProps> = ({
	className,
	grouping,
	level,
	type,
	lastGroupings = [],
}) => {
	const { currentGrouping } = useOrdersPageContext();

	const expandedGroups: GroupingRequest[] = React.useMemo(
		() => [
			...lastGroupings,
			{
				type,
				value: grouping.id ?? '',
			} as GroupingRequest,
		],
		[lastGroupings, grouping.id, type],
	);

	if (level === OrderHistoryAccordionLevel.Second || currentGrouping === OrderHistorySearchGroupingType.ShipTo) {
		return <OrderLineList collapseGroups={expandedGroups} />;
	}
	return (
		<Suspense fallback={<span className={classNames('u-spinner-horizontal', styles.spinner)} />}>
			<OrderAccordion
				className={className}
				grouping={grouping}
				level={level}
				type={type}
				expandedGroups={expandedGroups}
			/>
		</Suspense>
	);
};
