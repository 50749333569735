import { FetchNextPageOptions, UseInfiniteQueryResult, FetchPreviousPageOptions } from '@tanstack/react-query';
import { PriceQueryProps } from 'components/shared/Filters/RangeFilter';
import { FilterRequest, ProductItemResponse, ProductListResponse } from 'generated/data-contracts';

export const skeletonProductCards = (count = 8): ProductItemResponse[] => {
	return Array.from(Array(count));
};

export interface UseProductListActionsProps {
	hasPreviousPage: boolean | undefined;
	pageData: ProductListResponse | undefined;
	products: ProductItemResponse[];
	hasNextPage: boolean | undefined;
	fetchNextPage: (options?: FetchNextPageOptions) => Promise<UseInfiniteQueryResult>;
	fetchPreviousPage: (options?: FetchPreviousPageOptions) => Promise<UseInfiniteQueryResult>;
	changeFilter: (id: string) => void;
	changeSorting: (sortBy: string, sortDirection: string) => Promise<void>;
	changePriceFilter: () => void;
	changeFilterMobile: (sortBy: string, sortDirection: string) => void;
	resetFilters: () => void;
	loading: boolean;
	toBeSet: FilterRequest[];
	toBeRemoved: FilterRequest[];
	setToBeSetFilters: React.Dispatch<React.SetStateAction<FilterRequest[]>>;
	setToBeRemovedFilters: React.Dispatch<React.SetStateAction<FilterRequest[]>>;
	priceFilter: PriceQueryProps;
	setPriceFilter: React.Dispatch<React.SetStateAction<PriceQueryProps>>;
	removeFilter: (id: string, value?: string) => void;
	updateNavParams?: (productIndex: number) => void;
	changeCategory?: (categoryId: string) => void;
	uniqueFilterId?: string;
}
