import React, { PropsWithChildren } from 'react';
import { uniqueId } from 'lodash';
import { DragHandleElement } from './DragHandle';

interface UseDragToCopyReturnType {
	draggableCellContent: (input: { rowIndex: number; columnIndex: number }) => {
		'data-draggable-cell': string;
		'data-draggable-container': string;
		'aria-rowindex': number;
		'aria-colindex': number;
	};
}

interface DragToCopyContextProps {
	multiDirectional?: boolean;
}

const addStyles = (element: HTMLElement) => {
	element.style.cursor = 'grab';
	element.style.backgroundColor = 'var(--semantic-background-system-success-weak)';
	element.style.userSelect = 'none';
};

const removeStyles = (element: HTMLElement) => {
	element.style.cursor = '';
	element.style.backgroundColor = '';
	element.style.userSelect = '';
};

const useDragToCopyState = ({ multiDirectional = false }: DragToCopyContextProps): UseDragToCopyReturnType => {
	const DATA_DRAGGABLE_CELL_ATTRIBUTE = 'data-draggable-cell';
	const CONTAINER_ID = React.useRef<string>(uniqueId('draggable-cells-container-')).current;
	const DATA_CONTAINER_ATTRIBUTE = 'data-draggable-container';
	const DATA_DRAG_HANDLE = 'data-drag-handle';
	const DATA_DRAG_TYPE = 'data-drag-type';
	const DATA_ROW_INDEX = 'aria-rowindex';
	const DATA_COLUMN_INDEX = 'aria-colindex';
	const CELL_QUERY_SELECTOR = `[${DATA_DRAGGABLE_CELL_ATTRIBUTE}][${DATA_CONTAINER_ATTRIBUTE}="${CONTAINER_ID}"]`;

	const getIndexesFromCell = (cell?: Element) => {
		return {
			rowIndex: parseInt(cell?.getAttribute(DATA_ROW_INDEX) ?? ''),
			columnIndex: parseInt(cell?.getAttribute(DATA_COLUMN_INDEX) ?? ''),
		};
	};

	const eventHandlers = React.useCallback(() => {
		let content: unknown;
		let dataType: string;
		let isDragging = false;
		let startCell: HTMLElement | null = null;
		let endCell: HTMLElement | null = null;
		let currentHighlightedCell: HTMLElement | null = null;
		let currentAxis: 'x' | 'y' | null = null;

		const getSupportedDragHandles = () => `[${DATA_DRAG_HANDLE}][${DATA_DRAG_TYPE}=${dataType}]`;

		/**
		 *
		 * @returns A query selector that selects all cells between the start and end cell.
		 */
		const getQuerySelector = () => {
			if (!startCell) return CELL_QUERY_SELECTOR;
			const { rowIndex, columnIndex } = getIndexesFromCell(startCell);
			const currentCellPos = getIndexesFromCell(endCell ?? startCell);

			const rowQuery = `[${DATA_ROW_INDEX}="${rowIndex}"]`;
			const columnQuery = `[${DATA_COLUMN_INDEX}="${columnIndex}"]`;

			let query: string = '';
			const minColumnIndex = Math.min(columnIndex, currentCellPos.columnIndex);
			const maxColumnIndex = Math.max(columnIndex, currentCellPos.columnIndex);
			const minRowIndex = Math.min(rowIndex, currentCellPos.rowIndex);
			const maxRowIndex = Math.max(rowIndex, currentCellPos.rowIndex);

			for (let rowIndex = minRowIndex; rowIndex <= maxRowIndex; rowIndex++) {
				for (let colIndex = minColumnIndex; colIndex <= maxColumnIndex; colIndex++) {
					if (query.length > 0) {
						query += ',';
					}
					query += `${CELL_QUERY_SELECTOR}[${DATA_COLUMN_INDEX}="${colIndex}"][${DATA_ROW_INDEX}="${rowIndex}"]:has(> ${getSupportedDragHandles()})`;
				}
			}
			return query || `${CELL_QUERY_SELECTOR}${rowQuery}${columnQuery}`;
		};

		/**
		 * Selects the cell that's being dragged but finding the draghandle that was clicked,
		 * and then finding the closest cell.
		 */
		const handleMouseDown = (event) => {
			const isMouseEvent = event instanceof MouseEvent;
			const isTargetAnElement = event.target instanceof Element;
			if (!isMouseEvent || !isTargetAnElement) return;

			const clickTarget = event.target as HTMLElement;
			const selectedDragHandle: DragHandleElement<typeof content> | null = clickTarget.closest(
				`${CELL_QUERY_SELECTOR} > [${DATA_DRAG_HANDLE}]`,
			);
			if (!selectedDragHandle) return;
			const selectedCell: HTMLElement | null = selectedDragHandle.closest(CELL_QUERY_SELECTOR);
			if (!selectedCell) return;
			isDragging = true;
			startCell = selectedCell;
			dataType = selectedDragHandle.getAttribute(DATA_DRAG_TYPE) ?? '';
			if (selectedDragHandle.ondragcontentstart) {
				content = selectedDragHandle.ondragcontentstart();
			}
			addStyles(selectedCell as HTMLElement);
		};

		const handleMouseMove = (event) => {
			const isMouseEvent = event instanceof MouseEvent;
			if (!isMouseEvent) return;

			if (!isDragging || !startCell) return;
			const position = { x: event.clientX, y: event.clientY };
			const highlightedCell: HTMLElement | null | undefined = document
				.elementFromPoint(position.x, position.y)
				?.closest(CELL_QUERY_SELECTOR);

			if (!highlightedCell) return;
			if (currentHighlightedCell === highlightedCell) return;
			if (!currentHighlightedCell) {
				const oldPos = getIndexesFromCell(startCell);
				const newPos = getIndexesFromCell(highlightedCell);
				currentAxis = oldPos.rowIndex === newPos.rowIndex ? 'x' : 'y';
			}
			if (highlightedCell === startCell) {
				drawStyles();
				endCell = null;
				currentHighlightedCell = null;
				currentAxis = null;
				return;
			}
			currentHighlightedCell = highlightedCell;

			const oneDirectionalEndCellQuery = `${CELL_QUERY_SELECTOR}[${DATA_ROW_INDEX}="${
				currentAxis === 'y'
					? highlightedCell.getAttribute(DATA_ROW_INDEX)
					: startCell.getAttribute(DATA_ROW_INDEX)
			}"][${DATA_COLUMN_INDEX}="${
				currentAxis === 'x'
					? highlightedCell.getAttribute(DATA_COLUMN_INDEX)
					: startCell.getAttribute(DATA_COLUMN_INDEX)
			}"]`;

			const biDirectionalEndCellQuery = `${CELL_QUERY_SELECTOR}[${DATA_ROW_INDEX}="${highlightedCell.getAttribute(
				DATA_ROW_INDEX,
			)}"][${DATA_COLUMN_INDEX}="${highlightedCell.getAttribute(DATA_COLUMN_INDEX)}"]`;

			endCell = document.querySelector(multiDirectional ? biDirectionalEndCellQuery : oneDirectionalEndCellQuery);
			if (!endCell) return;
			drawStyles();
		};

		const handleMouseUp = () => {
			if (!isDragging) return;

			const cells = document.querySelectorAll(getQuerySelector());
			if (cells) {
				cells.forEach((cell) => {
					const dragHandle: DragHandleElement<typeof content> | null = cell.querySelector(
						getSupportedDragHandles(),
					);
					dragHandle?.ondropcontent?.(content);
				});
			}
			// Remove styles from all cells, as the drag operation has ended.
			document.querySelectorAll(CELL_QUERY_SELECTOR).forEach((cell) => {
				removeStyles(cell as HTMLElement);
			});

			content = null;
			dataType = '';
			isDragging = false;
			startCell = null;
			endCell = null;
			currentHighlightedCell = null;
			currentAxis = null;
		};

		/** On every cell change, we need to re-draw the styles for said cells.
		 * 	The styles added are defined in the `addStyles` function.
		 * 	The styles removed are defined in the `removeStyles` function.
		 *
		 * to remove the styles, we get all cells that fit the CELL_QUERY_SELECTOR, but are not present in the current selection (`getQuerySelector()`)
		 *
		 */
		const drawStyles = () => {
			if (isDragging) {
				const cells = document.querySelectorAll(getQuerySelector());
				if (cells) {
					cells.forEach((cell) => {
						addStyles(cell as HTMLElement);
					});
				}

				const cellsToRemoveStyles = document.querySelectorAll(
					`${CELL_QUERY_SELECTOR}:not(${getQuerySelector()})`,
				);
				if (cellsToRemoveStyles) {
					cellsToRemoveStyles.forEach((cell) => {
						removeStyles(cell as HTMLElement);
					});
				}
			}
		};

		return {
			handleMouseDown,
			handleMouseUp,
			handleMouseMove,
			drawStyles,
		};
	}, [CELL_QUERY_SELECTOR, multiDirectional]);

	React.useEffect(() => {
		const { handleMouseDown, handleMouseUp, handleMouseMove } = eventHandlers();
		const container = document;
		if (!container) return;

		container.addEventListener('mousedown', handleMouseDown);
		container.addEventListener('mousemove', handleMouseMove);
		container.addEventListener('mouseup', handleMouseUp);

		return () => {
			container.removeEventListener('mousedown', handleMouseDown);
			container.removeEventListener('mousemove', handleMouseMove);
			container.removeEventListener('mouseup', handleMouseUp);
		};
	}, [eventHandlers]);

	const draggableCellContent = React.useCallback(
		(input: { rowIndex: number; columnIndex: number }) => {
			return {
				[DATA_DRAGGABLE_CELL_ATTRIBUTE]: '',
				[DATA_CONTAINER_ATTRIBUTE]: CONTAINER_ID,
				[DATA_ROW_INDEX]: input.rowIndex,
				[DATA_COLUMN_INDEX]: input.columnIndex,
			};
		},
		[CONTAINER_ID],
	);
	return {
		draggableCellContent,
	};
};

const DragToCopyContext = React.createContext<UseDragToCopyReturnType | null>(null);

export const DragToCopyProvider: React.FunctionComponent<PropsWithChildren<DragToCopyContextProps>> = ({
	children,
	...props
}: PropsWithChildren<DragToCopyContextProps>) => {
	const value = useDragToCopyState(props);
	return <DragToCopyContext.Provider value={value}>{children}</DragToCopyContext.Provider>;
};

export const useDragToCopy = (): UseDragToCopyReturnType => {
	const context = React.useContext(DragToCopyContext);

	if (!context) {
		throw new Error('useDragToCopy must be used within a DragToCopyProvider');
	}

	return context;
};
