import React from 'react';
import classNames from 'classnames';
import { SupportedCssUnits } from 'types/cssUnits';
import { logos } from 'assets/logos';
import styles from './Logo.module.scss';

interface LogoProps {
	className?: string;
	svgClassName?: string;
	name: string;
	width?: SupportedCssUnits;
	height?: SupportedCssUnits;
}

/**
 * Generic Logo component used for displaying svg logos with specific width and height set on them. '
 * "name" should be one of the logos' file name exported from assets/logos.
 *
 * The difference between an Icon and Logo component is, that logos can (and should) have custom size.
 */
export const Logo: React.FunctionComponent<LogoProps> = ({ className, svgClassName, name, width, height }) => {
	const ref = React.useRef<SVGSVGElement>(null);

	// Automagically sets the viewbox of the svg based on the sprite's height and width, centering it.
	const setAutoViewBox = () => {
		const svg = ref.current;
		if (!svg) return;
		const useElement = svg.querySelector('use');
		if (!useElement) return;
		const bb = useElement.getBoundingClientRect();
		const [width, height] = [bb.width, bb.height];
		const newViewBox = [0, 0, width, height].map((val) => {
			return Math.ceil(val);
		});
		svg.setAttribute('viewBox', newViewBox.join(' '));
	};
	React.useLayoutEffect(setAutoViewBox, []);
	return (
		<i
			className={classNames(styles.logo, { [styles.hasSize]: width }, className)}
			style={
				{
					'--width': width,
					'--height': height,
				} as React.CSSProperties
			}
		>
			<svg ref={ref} className={classNames(styles.asset, svgClassName)}>
				<use xlinkHref={`#${logos[name]}`} />
			</svg>
		</i>
	);
};
