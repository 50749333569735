import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Carousel } from 'components/shared/Carousel/Carousel';
import { CarouselProvider, useCarouselStore } from 'components/shared/Carousel/state/CarouselContext';
import { ProductImageColor } from 'components/shared/ColorDot';
import { Link } from 'components/shared/Link';
import { LoadingBar } from 'components/shared/LoadingBar';
import { ProductImage } from 'components/shared/ProductImage';
import { Area } from 'generated/data-contracts';
import { ProductCardImageWrapper } from '../ProductCardImageWrapper';
import { useProductCardContext } from '../context/ProductCardContext';
import styles from './ProductCardImage.module.scss';
import { ProductCardImageGalleryNavigation } from './ProductCardImageGalleryNavigation';

interface ProductCardImageProps {
	className?: string;
}

type ProductCardImageSubComponents = {
	Wrapper: typeof ProductCardImageWrapper;
};

export const ProductCardImageInner: FC<ProductCardImageProps> = ({ className }) => {
	const {
		activeMaster: activeMaster,
		isSkeleton,
		isBeingHovered,
		product,
		productUrl,
		updateNavParams,
	} = useProductCardContext();

	const colorImages: ProductImageColor[] | undefined = activeMaster?.images?.map((image) => {
		return { ...image, color: activeMaster.colour };
	});
	const {
		slides: { activeSlide, setActiveSlide },
	} = useCarouselStore();

	React.useEffect(() => {
		setActiveSlide(0);
	}, [activeMaster?.id, setActiveSlide]);

	const route = useMemo(
		() => ({
			area: Area.Product,
			productFamilyId: product?.familyId,
			externalRoute: productUrl,
		}),
		[product?.familyId, productUrl],
	);

	const items = useMemo(() => {
		if (!activeMaster)
			return [
				<ProductImage
					key={`${product?.familyId}-galleryImage`}
					className={classNames(styles.wrapper, {
						[styles.skeleton]: isSkeleton,
					})}
					width={'100%'}
					altText={''}
					isSkeleton
					hasColorDotText
					colorDotSize="xl"
				/>,
			];
		return colorImages?.map((image, index) => {
			const mainImage = image?.size450;
			return (
				<Link
					key={`${activeMaster?.id}-${product?.familyId}-galleryImage-${index}`}
					className={classNames(styles.wrapper)}
					onClick={updateNavParams}
					to={productUrl}
					route={route}
				>
					<ProductImage
						className={classNames({
							[styles.img]: image?.size450,
							[styles.onHover]: isBeingHovered && activeSlide === index,
							[styles.skeleton]: isSkeleton,
						})}
						desktopSrc={mainImage}
						mobileSrc={mainImage}
						altText={image?.altText || ''}
						colorInfo={image.color}
						hasColorDotText
						isLazy
						colorDotSize="xl"
					/>
				</Link>
			);
		});
	}, [
		activeMaster,
		activeSlide,
		colorImages,
		isBeingHovered,
		isSkeleton,
		product?.familyId,
		productUrl,
		route,
		updateNavParams,
	]);

	if (isSkeleton) {
		return <LoadingBar className={classNames(styles.wrapper, styles.skeleton)} />;
	}
	if (items && items.length > 1) {
		return (
			<Carousel
				className={classNames(className, styles.wrapper)}
				listClassName={styles.slideList}
				Navigation={<ProductCardImageGalleryNavigation />}
			>
				{items}
			</Carousel>
		);
	}
	const firstImage = colorImages?.[0];
	return (
		<Link
			key={`${activeMaster?.id}-${product?.familyId}-galleryImage`}
			className={classNames(className, styles.wrapper)}
			to={productUrl}
			onClick={updateNavParams}
			route={route}
		>
			<ProductImage
				className={classNames(styles.wrapper, {
					[styles.img]: firstImage?.size450,
					[styles.onHover]: isBeingHovered,
					[styles.skeleton]: isSkeleton,
				})}
				width={'100%'}
				desktopSrc={firstImage?.size450}
				mobileSrc={firstImage?.size450}
				altText={firstImage?.altText || ''}
				isSkeleton={isSkeleton}
				colorInfo={activeMaster?.colour}
				hasColorDotText
				colorDotSize="xl"
			/>
		</Link>
	);
};

export const ProductCardImage: FC<ProductCardImageProps> & ProductCardImageSubComponents = (
	props: ProductCardImageProps,
) => {
	return (
		<CarouselProvider showBadge={false} showNavigation>
			<ProductCardImageInner {...props} />
		</CarouselProvider>
	);
};

ProductCardImage.Wrapper = ProductCardImageWrapper;
