import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useValidateProductBasketMutation } from '../api/productBasket';
import { queryKeys } from 'api/apiConfig';
import {
	BasketLine,
	BasketLineQuantityResponse,
	BasketLineRequest,
	ProductBasketRequest,
} from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { useDebounce } from 'helpers/useDebounce';
import { useViewportSize } from 'helpers/useViewportSize';

type ResponseType = BasketLineQuantityResponse | undefined;

interface UseBasketLinesUpdateContextReturnType {
	addBasketLine: (basketLine: BasketLineRequest) => Promise<ResponseType>;
	addBasketNote: (key: string, note: string) => void;
	prebasketLines: BasketLineRequest[];
	prebasketNotes: Record<string, string>;

	clearBasketLines: () => void;
}

interface RequestType {
	data: BasketLineRequest;
	resolve: (value?: ResponseType | PromiseLike<ResponseType>) => void;
	reject: (reason?: unknown) => void;
}

const findRelatedBasketLine = (basketLine: BasketLineRequest) => (newBasketLine: BasketLine) =>
	isEqual(
		{
			bundleId: newBasketLine.bundleId,
			deliveryDate: newBasketLine.deliveryDate,
			shipToId: newBasketLine.shipToId,
			variantId: newBasketLine.variantId,
		},
		{
			bundleId: basketLine.bundleId,
			deliveryDate: basketLine.deliveryDate,
			shipToId: basketLine.shipToId,
			variantId: basketLine.variantId,
		},
	);

export const useBasketLinesUpdate = (
	query: ProductBasketRequest,
	{
		allowedShipTos,
	}: {
		allowedShipTos?: string[];
	},
): UseBasketLinesUpdateContextReturnType => {
	const { isMobile } = useViewportSize();

	const [basketLines, setBasketLines] = React.useState<RequestType[]>([]);
	const [notes, setNotes] = React.useState<Record<string, string>>({});

	const { mutate } = useValidateProductBasketMutation();
	const queryClient = useQueryClient();
	const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(false);

	const filteredLines = React.useMemo(() => {
		if (allowedShipTos) {
			return basketLines.filter((basketLine) => allowedShipTos.includes(basketLine.data.shipToId));
		}
		return [];
	}, [allowedShipTos, basketLines]);

	const updateBasketLines: () => Promise<HttpResponse<ResponseType, unknown>[]> =
		// eslint-disable-next-line react-hooks/exhaustive-deps
		React.useCallback(
			useDebounce(
				() =>
					new Promise<HttpResponse<ResponseType, unknown>[]>(() => {
						if ((filteredLines.length === 0 && Object.keys(notes).length === 0) || shouldUpdate === false)
							return;
						mutate(
							{
								request: {
									...query,
									basketLines: filteredLines.map((basketLine) => basketLine.data),
									styleNotes: notes,
								},
							},
							{
								onSuccess: ({ data }) => {
									const queryKey = queryKeys.productBasket.current(query).queryKey;
									queryClient.setQueryData(queryKey, data);
									if (!data) basketLines.forEach((basketLine) => basketLine.reject());
									filteredLines.forEach((basketLine) => {
										const newBasketLine = data?.basketLines.find(
											findRelatedBasketLine(basketLine.data),
										);
										basketLine.resolve(newBasketLine);
									});

									setShouldUpdate(false);

									setBasketLines((prev) => {
										return prev.map((basketLine) => {
											const newBasketLine = data?.basketLines.find(
												findRelatedBasketLine(basketLine.data),
											);

											if (newBasketLine?.quantity === basketLine.data.quantity) {
												return basketLine;
											}
											return {
												data: {
													...basketLine.data,
													quantity: newBasketLine?.quantity ?? basketLine.data.quantity,
												},
												resolve: basketLine.resolve,
												reject: basketLine.reject,
											};
										});
									});
								},
								onError: (error) => {
									filteredLines.forEach((basketLine) => basketLine.reject(error));
								},
							},
						);
					}),
				10,
			),
			// shouldUpdate is not part of these, as we want to wait until
			// `filteredLines` * after * `shouldUpdate` has been updated
			[mutate, filteredLines, isMobile, notes],
		);

	React.useEffect(() => {
		updateBasketLines();
	}, [updateBasketLines]);

	const addBasketNote = (key, note) => {
		setShouldUpdate(true);
		setNotes((prev) => ({
			...prev,
			[key]: note,
		}));
	};

	const addBasketLine = React.useCallback((basketLine: BasketLineRequest): Promise<ResponseType> => {
		return new Promise<ResponseType>((resolve, reject) => {
			setShouldUpdate(true);
			setBasketLines((prev) => {
				const newBasketLines = [...prev];
				const basketLineIndex = newBasketLines.findIndex((prevBasketLine) =>
					isEqual({ ...prevBasketLine.data, quantity: undefined }, { ...basketLine, quantity: undefined }),
				);

				basketLineIndex === -1
					? newBasketLines.push({
							data: basketLine,
							resolve,
							reject,
					  })
					: (newBasketLines[basketLineIndex] = {
							data: basketLine,
							resolve,
							reject,
					  });
				return newBasketLines;
			});
		});
	}, []);

	const prebasketLines = React.useMemo(() => {
		return basketLines.map((basketLine) => basketLine.data);
	}, [basketLines]);

	return {
		addBasketLine,
		addBasketNote,
		prebasketLines,
		prebasketNotes: notes,
		clearBasketLines: () => {
			setBasketLines([]);
			queryClient.invalidateQueries({ queryKey: queryKeys.productBasket.current._def });
		},
	};
};
