import React, { FC, useMemo } from 'react';
import { useLayoutQuery } from 'api/layout';
import { Roles } from 'api/roles';
import { useTranslationQuery } from 'api/translations';
import { Icon, Link } from 'components/shared';
import { FeatureFlag, PageType } from 'generated/data-contracts';
import styles from './ManageUsers.module.scss';

export const ManageUsers: FC = () => {
	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();

	const ff = layout?.userFeatureFlags.includes(FeatureFlag.CustomerManagement);

	const isAccessGranted = useMemo(
		() =>
			layout?.user?.userRole === Roles.SalesPerson ||
			layout?.user?.userRole === Roles.Admin ||
			layout?.user?.userRole === Roles.CustomerAdmin ||
			layout?.user?.userRole === Roles.CustomerUser,
		[layout?.user?.userRole],
	);

	if (!ff || !isAccessGranted) {
		return null;
	}

	const link = layout?.staticLinks.find((l) => l.pageType === PageType.CustomerManagement);

	if (!link) {
		return null;
	}

	return (
		<Link className={styles.title} linkUrl={link.url} hasExternalIconHidden>
			<Icon className={'u-mr-sm'} name={'users'} size={'md'} />
			{translations?.myProfile.manageUsers}
		</Link>
	);
};
