import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { useSearchParams } from 'library/routing';
import { isEqual, uniq } from 'lodash';
import { useBasketQuery } from 'api/newBasket';
import { useFilterContext } from 'components/shared/FilterList/V2/context/FilterContext';
import { FilterTypesEnum } from 'components/shared/FilterList/V2/types/FilterTypes';
import {
	OrderHistoryViewportSize,
	useOrderHistoryViewportSize,
} from 'components/shared/hooks/useOrderHistoryViewportSize';
import {
	ExportInformationResponse,
	FilterRequest,
	OrderHistorySearchGroup,
	OrderHistorySort,
	SortDirection,
	SortingResponseOrderHistorySort,
} from 'generated/data-contracts';
import { convertFiltersToFilterRequest } from '../helpers/convertFiltersToFilterRequests';
import { useSearch, UseSearchReturnType } from 'helpers/useSearch';
import { OrderListUrlParamKeys, PREDEFINED_ORDERLIST_URL_PARAMS } from '../constants/OrderListUrlParamKeys';
import {
	GroupingOption,
	OrderHistorySearchGroupingTypeExtended,
	OrderHistorySearchGroupingTypeExtendedValue,
	useGroupingFilter,
} from '../hooks/useGroupingFilter';
import { useSortingOptions } from '../hooks/useSortingOptions';

type LayoutInfo = {
	totalValue?: string;
	totalQuantity?: number;
};

type UseOrdersPageContextResponse = {
	status: OrderHistorySearchGroup;
	size: OrderHistoryViewportSize;
	changeSort: (sort: OrderHistorySort, sortDirection: SortDirection) => void;
	groupingOptions: GroupingOption[];
	currentGrouping: OrderHistorySearchGroupingTypeExtended;
	changeGrouping: (grouping: OrderHistorySearchGroupingTypeExtended) => void;
	search: UseSearchReturnType;
	currentSort?: SortingResponseOrderHistorySort;
	sortOptions?: SortingResponseOrderHistorySort[];
	setSortOptions: React.Dispatch<SortingResponseOrderHistorySort[] | undefined>;
	selectedShipTos: string[] | undefined;
	filterRequests: FilterRequest[];
	isOnOrderOverview: boolean;
	exportInformation?: ExportInformationResponse;
	setExportInformation: React.Dispatch<React.SetStateAction<ExportInformationResponse | undefined>>;
	layoutInfo?: LayoutInfo;
	setLayoutInfo: React.Dispatch<React.SetStateAction<LayoutInfo | undefined>>;
};

const useOrdersPageState = (): UseOrdersPageContextResponse => {
	const { data: shipTos } = useBasketQuery();
	const [layoutInfo, setLayoutInfo] = React.useState<LayoutInfo>();
	const [exportInformation, setExportInformation] = React.useState<ExportInformationResponse>();
	const { changeGrouping, groupingOptions, currentGrouping } = useGroupingFilter();
	const search = useSearch();
	const { changeSort, currentSort, setSortOptions, sortOptions } = useSortingOptions();
	const size = useOrderHistoryViewportSize(currentGrouping);
	const filters = useFilterContext();
	const [searchParams] = useSearchParams();

	const selectedShipTos = React.useMemo(() => {
		const selectedShipTosValues = filters.selectedFilters[FilterTypesEnum.Checkbox]
			.get(OrderListUrlParamKeys.ShipTo)
			?.keys();
		if (!selectedShipTosValues) return [];
		return uniq([...selectedShipTosValues]);
	}, [filters.selectedFilters]);
	const filterRequests = React.useMemo(
		() =>
			convertFiltersToFilterRequest(filters.selectedFilters, {
				excluded: PREDEFINED_ORDERLIST_URL_PARAMS,
			}),
		[filters.selectedFilters],
	);
	const isOnOrderOverview = currentGrouping === OrderHistorySearchGroupingTypeExtendedValue.Overview;
	const initializedShipTos = useRef<string[]>([]);
	const selectedShipToLength =
		filters.selectedFilters[FilterTypesEnum.Checkbox].get(OrderListUrlParamKeys.ShipTo)?.size ?? 0;

	/**
	 * For the MyOrders shipTo filter, some special rules apply.
	 *
	 * These need to be instantiated with the shipTos from the basket, IF the url does not contain any shipTos.
	 * However, if the shipTos in the basket are changed, the filter must also be set to those shipTos, overwriting the old value.
	 */
	useEffect(() => {
		if (!shipTos) return;
		if (
			selectedShipToLength === 0 ||
			(initializedShipTos.current.length !== 0 && !isEqual(initializedShipTos.current, shipTos.basketShipTos))
		) {
			filters.checkboxActions.onInitializeCheckboxFilter(
				OrderListUrlParamKeys.ShipTo,
				shipTos.basketShipTos.map((v) => ({
					isSelected: true,
					label: v,
					value: v,
				})),
			);
		}
		initializedShipTos.current = shipTos.basketShipTos;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedShipToLength, filters.checkboxActions.onInitializeCheckboxFilter, shipTos]);

	return {
		status: (searchParams.get(OrderListUrlParamKeys.Tab) as OrderHistorySearchGroup) ?? OrderHistorySearchGroup.All,
		filterRequests,
		selectedShipTos,
		search,
		size,
		changeGrouping,
		groupingOptions,
		currentGrouping,
		changeSort,
		setSortOptions,
		currentSort,
		sortOptions,
		isOnOrderOverview,
		layoutInfo,
		setLayoutInfo,
		exportInformation,
		setExportInformation,
	};
};

const OrdersPageContext = React.createContext<UseOrdersPageContextResponse | null>(null);

export const OrdersPageContextProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
	const ordersActions = useOrdersPageState();
	return <OrdersPageContext.Provider value={ordersActions}>{children}</OrdersPageContext.Provider>;
};

export const useOrdersPageContext = (): UseOrdersPageContextResponse => {
	const context = useContext(OrdersPageContext);

	if (!context) {
		throw new Error('useOrdersActionsContext must be used within a OrdersActionsContextProvider');
	}

	return context;
};
