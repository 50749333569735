import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Dropdown } from 'components/shared';
import { SortDirection } from 'generated/data-contracts';
import styles from './SortDropdown.module.scss';
import { SortOption, SortSection } from './SortSection';

export type SortOptionsObject<T extends string> = Record<T, SortOption<T>>;

export interface SortDropdownProps<T extends string> {
	className?: string;
	inputName?: string;
	defaultOption: T;
	disabled?: boolean;
	options: Map<T, SortOption<T>>;
	onChangeSort?: (sortOption: T, sortDirection: SortDirection) => void;
}

export const SortDropdown = <T extends string>({
	inputName,
	options,
	defaultOption,
	onChangeSort,
	disabled = false,
}: SortDropdownProps<T>) => {
	const { data: translations } = useTranslationQuery();
	const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.Descending);
	const [sortBy, setSortBy] = React.useState<T>(defaultOption);

	React.useEffect(() => {
		if (sortBy) onChangeSort?.(sortBy, sortDirection);
	}, [onChangeSort, sortDirection, sortBy]);
	if (!translations) return;
	return (
		<>
			<Dropdown
				leadingIcon="sort"
				buttonLabel={translations.shared.sorting.sortBy}
				buttonVariant={'ghost'}
				buttonSize="sm"
				className={styles.dropdownButton}
				contentClassName={styles.dropdownContent}
				unmountOnClose={false}
				disabled={disabled}
				contentFullWidth
			>
				<SortSection
					sortBy={sortBy}
					sortDirection={sortDirection}
					options={options}
					setSortBy={setSortBy}
					setSortDirection={setSortDirection}
				/>
			</Dropdown>
			{inputName && <input name={inputName} type="text" value={sortBy} readOnly hidden />}
		</>
	);
};
