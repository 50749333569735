import React from 'react';
import classNames from 'classnames';
import { skeletonProductCards } from 'components/features/ProductList/helpers';
import { PaginationNext } from 'components/fragments/Pagination/PaginationNext';
import { PaginationPrevious } from 'components/fragments/Pagination/PaginationPrevious';
import { InfiniteScrollTrigger } from 'components/shared/InfiniteScrollTrigger';
import { PlpProductCard } from 'components/shared/ProductCard/PlpProductCard';
import { usePlpContext } from '../../context/PlpContext';
import { Categories } from '../CategoryMenu';
import { LayoutWrapper } from '../LayoutWrapper';
import { ListWrapper } from '../ListWrapper';
import { NoResults } from '../NoResults';
import { ProductListHeader } from '../ProductListHeader';
import styles from './ProductList.module.scss';

type ProductListSubComponents = {
	FilterHeader: typeof ProductListHeader;
	Wrapper: typeof ListWrapper;
	Layout: typeof LayoutWrapper;
	Categories: typeof Categories;
};

export const ProductList: React.FC & ProductListSubComponents = () => {
	const { products, isStale, pagination, updatePageParamForIndex } = usePlpContext();
	const NUMBER_OF_LOADING_PRODUCTS = 6;
	return (
		<div className={styles.container}>
			{pagination.paginationStrategy !== 'disabled' &&
				pagination.hasPreviousPage &&
				!pagination.isFetchingPreviousPage && (
					<PaginationPrevious fetchPreviousPage={pagination.fetchPreviousPage} />
				)}
			<section
				className={classNames(styles.productList, {
					[styles.isStale]: isStale,
				})}
				style={{ '--js-number-of-columns': 'auto-fill' } as React.CSSProperties}
			>
				{pagination.isFetchingPreviousPage &&
					skeletonProductCards(NUMBER_OF_LOADING_PRODUCTS).map((_, index) => (
						<PlpProductCard key={`skeleton${index}`} />
					))}
				{products.products.map((r, index) => (
					<PlpProductCard
						product={r}
						key={`${r.id}`}
						updateNavParams={() => {
							updatePageParamForIndex?.(index);
						}}
					/>
				))}
				{pagination.isFetchingNextPage &&
					skeletonProductCards(NUMBER_OF_LOADING_PRODUCTS).map((_, index) => (
						<PlpProductCard key={`skeleton${index}`} />
					))}

				{products.pagingInformation.totalNumberOfItems === 0 && <NoResults />}
			</section>
			{pagination.paginationStrategy === 'page' &&
				products.pagingInformation.totalNumberOfItems > 0 &&
				!pagination.isFetchingNextPage && (
					<PaginationNext
						fetchNextPage={pagination.fetchNextPage}
						totalCount={products.pagingInformation.totalNumberOfItems}
						pageSize={products.pagingInformation.pageSize}
						currentPage={
							products.pagingInformation.currentPage -
							1 +
							Math.ceil(products.products.length / products.pagingInformation.pageSize)
						}
						hasNextPage={pagination.hasNextPage}
					/>
				)}
			{pagination.paginationStrategy === 'infinite' &&
				products.pagingInformation.totalNumberOfItems > 0 &&
				!pagination.isFetchingNextPage && (
					<InfiniteScrollTrigger
						hasMore={pagination.hasNextPage}
						onLoadMore={pagination.fetchNextPage}
						as="div"
					/>
				)}
		</div>
	);
};

ProductList.Categories = Categories;
ProductList.Wrapper = ListWrapper;
ProductList.Layout = LayoutWrapper;
ProductList.FilterHeader = ProductListHeader;
