import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'library/routing';
import { useTranslationQuery } from 'api/translations';
import { SortOption } from 'components/fragments/SortDropdown/SortSection';
import { SortDirection, ProductSort } from 'generated/data-contracts';
import { PLPUrlParamKeys } from '../constants/PLPUrlParams';

type SortType = ProductSort;

export interface UsePlpSortByReturnType {
	sortBy: SortType;
	sortDirection: SortDirection;
	setSortBy: React.Dispatch<React.SetStateAction<SortType>>;
	setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
	sortOptions: Map<SortType, SortOption<SortType>>;
}

const DEFAULT_SORT_OPTION = ProductSort.Relevance;

type UsePLPSortByProps = {
	defaultSortOption?: ProductSort;
	defaultSortDirection?: SortDirection;
} & (
	| {
			storeInUrl?: never | false;
			sortByUrlParam?: never;
			sortDirectionUrlParam?: never;
	  }
	| {
			storeInUrl: true;
			sortByUrlParam?: string;
			sortDirectionUrlParam?: string;
	  }
);

export const usePlpSortBy = (props: UsePLPSortByProps = {}): UsePlpSortByReturnType => {
	const location = useLocation();
	const {
		defaultSortOption = DEFAULT_SORT_OPTION,
		defaultSortDirection = SortDirection.Descending,
		storeInUrl = true,
		sortByUrlParam = PLPUrlParamKeys.SortBy,
		sortDirectionUrlParam = PLPUrlParamKeys.SortDirection,
	} = props;
	const { data: translations } = useTranslationQuery();
	const [searchParams, setSearchParams] = useSearchParams();
	const sortOptions = React.useMemo<Map<SortType, SortOption<SortType>>>(() => {
		return new Map([
			[
				ProductSort.Relevance,
				{
					label: translations.productList.sorting.relevance,
					value: ProductSort.Relevance,
					only: SortDirection.Descending,
				},
			],
			[ProductSort.Rrp, { label: translations.productList.sorting.rrp, value: ProductSort.Rrp }],
			[
				ProductSort.WholesalePrice,
				{ label: translations.productList.sorting.wsp, value: ProductSort.WholesalePrice },
			],
			[ProductSort.Markup, { label: translations.productList.sorting.markup, value: ProductSort.Markup }],
			[ProductSort.Name, { label: translations.productList.sorting.name, value: ProductSort.Name }],
		]);
	}, [translations]);
	const [sortBy, setSortBy] = React.useState<SortType>(() => {
		if (!storeInUrl) return defaultSortOption;
		const value = searchParams.get(sortByUrlParam);
		const option = value ? sortOptions.get(value as SortType) : undefined;
		if (option) return option.value;
		return defaultSortOption;
	});
	const [sortDirection, setSortDirection] = React.useState<SortDirection>(() => {
		if (!storeInUrl) return defaultSortDirection;
		const value = searchParams.get(sortDirectionUrlParam);
		if (value === SortDirection.Ascending) return SortDirection.Ascending;
		return defaultSortDirection;
	});

	React.useEffect(() => {
		if (!storeInUrl) return;
		setSearchParams(
			(prev) => {
				const newSearchParams = new URLSearchParams(prev);
				if (sortBy === defaultSortOption) {
					newSearchParams.delete(sortByUrlParam);
				} else {
					newSearchParams.set(sortByUrlParam, sortBy);
				}

				if (sortDirection === defaultSortDirection) {
					newSearchParams.delete(sortDirectionUrlParam);
				} else {
					newSearchParams.set(sortDirectionUrlParam, sortDirection);
				}
				return newSearchParams;
			},
			{
				replace: true,
				state: location.state,
			},
		);
	}, [
		defaultSortDirection,
		defaultSortOption,
		location.state,
		setSearchParams,
		sortBy,
		sortByUrlParam,
		sortDirection,
		sortDirectionUrlParam,
		storeInUrl,
	]);

	return React.useMemo(
		() => ({
			sortBy,
			sortDirection,
			sortOptions,
			setSortBy,
			setSortDirection,
		}),
		[sortBy, sortDirection, sortOptions],
	);
};
