import React, { FC } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { usePlpContext } from 'components/fragments/Plp/context/PlpContext';
import { Accordion, Button } from 'components/shared';
import { MegaMenuItem } from 'generated/data-contracts';
import styles from './CategoryItem.module.scss';

export interface CategoryItemProps {
	item: MegaMenuItem;
}

export const CategoryItem: FC<CategoryItemProps> = ({ item }) => {
	const {
		products: { productCategoryIds },
		selectCategory,
		selectedCategory,
	} = usePlpContext();
	const isCategorySelected = (item: MegaMenuItem) => {
		return (
			item.route?.productCategoryId === selectedCategory.id ||
			item.children.some((c) => c.route?.productCategoryId === selectedCategory.id)
		);
	};
	if (item.route?.productCategoryId && !productCategoryIds.includes(item.route?.productCategoryId)) return null;
	if (!isEmpty(item.children)) {
		return (
			<li className={styles.listItem}>
				<Accordion.V2
					expanded={isCategorySelected(item)}
					onToggle={() => {}}
					summaryClassName={styles.summary}
					Summary={
						<Button
							hasNoStyles
							className={classNames(styles.categoryItem, {
								[styles.isSelected]: isCategorySelected(item),
								[styles.hasNoItems]:
									item.id &&
									(!productCategoryIds.includes(item.id) ||
										item.children.every((r) => !productCategoryIds.includes(r.id ?? ''))),
							})}
							onClick={() => {
								selectCategory(item);
							}}
						>
							{item.name}
						</Button>
					}
				>
					<ul className={styles.subCategories}>
						{item.children.map((sc) => (
							<CategoryItem key={sc.route?.productCategoryId ?? sc.route?.area ?? sc.name} item={sc} />
						))}
					</ul>
				</Accordion.V2>
			</li>
		);
	}
	return (
		<li className={styles.listItem}>
			<Button
				hasNoStyles
				className={classNames(styles.subcategoryItem, {
					[styles.isSelected]: isCategorySelected(item),
					[styles.hasNoItems]: item.id && !productCategoryIds.includes(item.id),
				})}
				onClick={() => {
					selectCategory(item);
				}}
			>
				{item.name}
			</Button>
		</li>
	);
};
