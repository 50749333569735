import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import styles from './FilterListV2.module.scss';
import { CheckBoxFilter } from './Filters/CheckBoxFilter';
import { RangeFilter } from './Filters/RangeFilter';
import { SearchableFilter } from './Filters/SearchableFilter';
import { SingleFilter } from './Filters/SingleFilter';
import { useFilterContext } from './context/FilterContext';
import { FilterTypesEnum } from './types/FilterTypes';

export interface FilterListV2Props {
	numberOfShownFiltersByDefault: number;
	exclude?: string[];
}

export const FilterListV2: React.FunctionComponent<FilterListV2Props> = ({
	numberOfShownFiltersByDefault = 3,
	exclude: excludeFilters,
}) => {
	const { data: translations } = useTranslationQuery();
	const { filters, styling } = useFilterContext();
	const [numberOfShownFilters, setNumberOfShownFilters] = React.useState<number | undefined>(
		numberOfShownFiltersByDefault,
	);

	const showAllFilters = () => {
		setNumberOfShownFilters(undefined);
	};

	const showAllFiltersButton =
		numberOfShownFilters &&
		filters.length > numberOfShownFiltersByDefault - 1 &&
		numberOfShownFilters <= numberOfShownFiltersByDefault &&
		filters.length !== numberOfShownFilters;

	return (
		<ul className={styles.filterList}>
			{filters
				.filter((r) => {
					if (excludeFilters && excludeFilters.includes(r.id)) return false;
					return true;
				})
				.slice(0, numberOfShownFilters)
				.map((filter) => {
					switch (filter.type) {
						case FilterTypesEnum.Checkbox:
							if ('search' in filter) {
								return (
									<li key={filter.id} className={styles.filter}>
										<SearchableFilter filter={filter} />
									</li>
								);
							}
							return (
								<li key={filter.id} className={styles.filter}>
									<CheckBoxFilter filter={filter} />
								</li>
							);
						case FilterTypesEnum.Range:
							return (
								<li key={filter.id} className={styles.filter}>
									<RangeFilter filter={filter} />
								</li>
							);
						case FilterTypesEnum.Single:
							return (
								<li key={filter.id} className={styles.filter}>
									<SingleFilter filter={filter} />
								</li>
							);
						default:
							return null;
					}
				})}
			{showAllFiltersButton && (
				<Button
					className={styles.allFiltersButton}
					size={styling.button.size ?? 'sm'}
					variant={styling.button.variant ?? 'outline'}
					onClick={showAllFilters}
				>
					<Icon name={'filters'} size={'sm'} />
					<span>{translations?.productList?.filters?.filtersButton}</span>
				</Button>
			)}
		</ul>
	);
};
