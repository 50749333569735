import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'library/routing';
import { queryKeys } from 'api/apiConfig';
import { useTranslationQuery } from 'api/translations';
import { Icon, Button } from 'components/shared';
import { ErrorFallbackProps } from 'components/shared/ErrorBoundary';
import styles from './PLPErrorBoundary.module.scss';

export const PLPErrorBoundary: React.FC<ErrorFallbackProps> = ({ resetError }) => {
	const [, setSearchParams] = useSearchParams();
	const { data: translations } = useTranslationQuery();
	const location = useLocation();
	const queryClient = useQueryClient();

	return (
		<section className={styles.container}>
			<div className={styles.content}>
				<h2 className={styles.title}>{translations.productList.couldNotLoadProductsError}</h2>
				<Button
					variant="outline"
					size="sm"
					onClick={async () => {
						await queryClient.removeQueries({ queryKey: queryKeys.product.list._def });
						setSearchParams(new URLSearchParams(), {
							replace: true,
							state: location.state,
						});
						resetError();
					}}
				>
					{translations.productList.filters.resetFilters}
					<Icon name="forward" size="sm" />
				</Button>
			</div>
		</section>
	);
};
