import React, { FC, useDeferredValue } from 'react';
import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'library/routing';
import { isEqual } from 'lodash';
import { BasketRequest, useBasketQuery, useBasketStyleNoteDeleteMutation } from 'api/newBasket';
import { useBasketStyleNoteMutation } from 'api/newBasket';
import { useTranslationQuery } from 'api/translations';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import {
	BasketFilterType,
	BasketLineQuantityResponse,
	BasketLineRequest,
	BasketResponse,
} from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import { useViewportSize } from 'helpers/useViewportSize';
import { useAvailabilityNotification } from '../hooks/useAvailabilityNotification';
import { useBasketDeliveryDateFilter, UseBasketDeliveryDateReturnType } from '../hooks/useBasketDeliveryDateFilter';
import { useBasketGrouping, UseBasketGroupingReturnType } from '../hooks/useBasketGrouping';
import { useBasketLinesUpdateState } from '../hooks/useBasketLinesUpdateState';
import { useBasketSortBy, UseBasketSortByReturnType } from '../hooks/useBasketSortBy';

const BASKET_ID_URL_PARAM = 'basketId';

export type BasketContextReturnType = {
	basket: BasketResponse;
	onQuantityUpdate: (basketLine: BasketLineRequest) => Promise<BasketLineQuantityResponse | undefined>;
	onAddNote: (note: string, key: string) => void;
	deliveryDate: UseBasketDeliveryDateReturnType;
	sort: UseBasketSortByReturnType;
	grouping: UseBasketGroupingReturnType;
	isSDO: boolean;
	isPending: boolean;
	isStale: boolean;
	filter: BasketFilterType;
};

type BasketProviderProps = {
	children: ((props: BasketContextReturnType) => React.ReactNode) | React.ReactNode;
};

const useBasketState = (): BasketContextReturnType => {
	const location = useLocation();
	const [, setSearchParams] = useSearchParams();
	const sort = useBasketSortBy();
	const grouping = useBasketGrouping();
	const deliveryDate = useBasketDeliveryDateFilter();
	const setStyleNote = useBasketStyleNoteMutation();
	const deleteStyleNote = useBasketStyleNoteDeleteMutation();
	const { isMobile } = useViewportSize();
	const { notificationActions } = useNotificationContext();
	const { data: translations } = useTranslationQuery();

	const currentShipmentFilter = deliveryDate.selectedDeliveryDate
		? BasketFilterType.DeliveryDate
		: BasketFilterType.All;

	const query = React.useMemo<BasketRequest>(
		() => ({
			groupingTypeRequest: grouping.currentGrouping,
			includeShipToGrouping: isMobile,
			shippingFilter: currentShipmentFilter,
			shippingFilterDate: deliveryDate.selectedDeliveryDate ?? undefined,
		}),
		[currentShipmentFilter, deliveryDate.selectedDeliveryDate, grouping.currentGrouping, isMobile],
	);
	const deferredRequest = useDeferredValue(query);

	const { data: basket, isPending } = useBasketQuery(deferredRequest);
	const isStale = !isEqual(deferredRequest, query);
	if (!basket) throw new Error('Basket data is not suspended');
	const addBasketLine = useBasketLinesUpdateState(query);
	const onNoteAdd = (note: string, key: string) => {
		if (note === '') {
			deleteStyleNote.mutate(
				{
					key: key,
				},
				{
					onSuccess: () => {
						notificationActions.addNotification({
							type: AlertTypes.SUCCESS,
							children: formatTranslation(translations?.basket.styleNote.successDelete, {}),
						});
					},
					onError: () => {
						notificationActions.addNotification({
							type: AlertTypes.DANGER,
							children: formatTranslation(translations?.basket.styleNote.errorDeleteFailed, {}),
						});
					},
				},
			);
		} else {
			setStyleNote.mutate(
				{
					query: {
						note: note,
					},
					key: key,
				},
				{
					onSuccess: () => {
						notificationActions.addNotification({
							type: AlertTypes.SUCCESS,
							children: formatTranslation(translations?.basket.styleNote.successUpdate, {}),
						});
					},
					onError: () => {
						notificationActions.addNotification({
							type: AlertTypes.DANGER,
							children: formatTranslation(translations?.basket.styleNote.errorUpdateFailed, {}),
						});
					},
				},
			);
		}
	};
	const { setAvailableDeliveryDates } = deliveryDate;

	React.useEffect(() => {
		if (basket) {
			setSearchParams(
				(prevParams) => {
					const updatedParams = new URLSearchParams(prevParams.toString());
					updatedParams.set(BASKET_ID_URL_PARAM, basket.id?.toString());
					return updatedParams.toString();
				},
				{
					replace: true,
					state: location.state,
				},
			);
		}
	}, [basket, location.state, setSearchParams]);

	React.useEffect(() => {
		setAvailableDeliveryDates(
			basket.lookups.shippingFilters.map((sf) => ({
				label: sf.name,
				value: sf.value,
				hitCount: sf.totalQuantity,
			})),
		);
	}, [basket.lookups.shippingFilters, setAvailableDeliveryDates]);

	useAvailabilityNotification(basket);

	const isSDO = basket.basketShipTos.length === 1;
	return {
		basket,
		deliveryDate,
		onQuantityUpdate: addBasketLine,
		onAddNote: onNoteAdd,
		sort,
		grouping,
		isSDO,
		filter: currentShipmentFilter,
		isPending,
		isStale,
	};
};

export const BasketContext = createContext<BasketContextReturnType | undefined>(undefined);

export const BasketContextProvider: FC<BasketProviderProps> = ({ children }: BasketProviderProps) => {
	const state = useBasketState();
	return (
		<BasketContext.Provider value={state}>
			{typeof children === 'function' ? children(state) : children}
		</BasketContext.Provider>
	);
};

export const useBasketContext = (): BasketContextReturnType => {
	const context = useContext(BasketContext);

	if (!context) {
		throw new Error(`useBasketContext must be used within a BasketContextProvider.`);
	}
	return context;
};
