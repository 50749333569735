import { Builder } from '@builder.io/react';
import { CmsPLP } from '.';
import { getSharedProductsFilters } from '../../shared/ProductsFilters';
import { PLP } from './DeprecatedCMSPlp';
import { FILTER_OPTIONS } from './filters/filters';
import { LAYOUT_OPTIONS } from './filters/layout';

Builder.registerComponent(PLP, {
	name: 'PLP',
	hideFromInsertMenu: true,
	image: new URL('/dist/images/cms/productSlider.svg', window.location.origin).href,
	inputs: [...getSharedProductsFilters('PLP')],
});

Builder.registerComponent(CmsPLP, {
	name: 'NewPLP',
	friendlyName: 'PLP',
	image: new URL('/dist/images/cms/productSlider.svg', window.location.origin).href,
	inputs: [FILTER_OPTIONS, LAYOUT_OPTIONS],
});
