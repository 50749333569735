import React, { Suspense } from 'react';
import { BuilderElement } from '@builder.io/sdk';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { ProductBundleQuery } from 'api/product';
import { ProductList } from 'components/fragments/Plp/components/ProductList';
import { CmsPlpContextProvider } from 'components/fragments/Plp/context/providers/CmsPlpProvider';
import { PLPErrorBoundary } from 'components/fragments/Plp/fallback/PLPErrorBoundary';
import { PLPFetching } from 'components/fragments/Plp/fallback/PLPFetching';
import { ErrorBoundary } from 'components/shared/ErrorBoundary';
import { FilterContextProvider } from 'components/shared/FilterList/V2/context/FilterContext';
import { ProductFilter } from 'generated/data-contracts';
import { useViewportSize } from 'helpers/useViewportSize';
import { CmsPlpFilterOptions } from './filters/filters';
import { CmsPlpLayoutOptions } from './filters/layout';

export interface CmsPLPProps {
	layout: CmsPlpLayoutOptions;
	filters: CmsPlpFilterOptions;
	builderBlock: BuilderElement;
}

export const CmsPLP: React.FunctionComponent<CmsPLPProps> = ({ layout, filters, builderBlock }) => {
	const { isMobile } = useViewportSize();
	const query: ProductBundleQuery = React.useMemo(() => {
		const {
			searchPhrase,
			categoryIds,
			subcategoryIds,
			collectionIds,
			productCatalogues,
			productSubcatalogues,
			productLineIds,
			productMyNoos,
			...unparsedProductFilters
		} = filters;

		const filterObject = Object.values(unparsedProductFilters)
			.flat()
			.reduce<Record<string, string[]>>((acc, cur) => {
				if (!cur || typeof cur !== 'string' || !cur.includes('@')) return acc;
				const [value, key] = cur.split('@');
				if (!acc[key]) acc[key] = [];
				acc[key].push(value);
				return acc;
			}, {});

		const productFilters: ProductFilter[] = Object.entries(filterObject).map(([key, values]): ProductFilter => {
			return {
				attributeKey: key,
				attributeValues: values,
			};
		});

		if (productMyNoos) {
			productFilters.push({
				attributeKey: 'MyNoos',
				attributeValues: ['True'],
			});
		}

		function valueOrFallback<T>(value: T, fallback: T) {
			return isEmpty(value) ? fallback : value;
		}

		return {
			phrase: valueOrFallback(searchPhrase, undefined),
			preDefinedProductFilters: productFilters,
			preDefinedProductCategoryIds: Array.isArray(categoryIds) ? categoryIds : undefined,
			preDefinedProductSubcategoryIds: Array.isArray(subcategoryIds) ? subcategoryIds : undefined,
			collectionIds: Array.isArray(collectionIds) ? collectionIds : undefined,
			productCatalogues: Array.isArray(productCatalogues) ? productCatalogues : undefined,
			productSubcatalogues: Array.isArray(productSubcatalogues) ? productSubcatalogues : undefined,
			productLineIds: Array.isArray(productLineIds) ? productLineIds : undefined,
			sortBy: valueOrFallback(layout.defaultSorting, undefined),
			sortDirection: valueOrFallback(layout?.sortingDirection, undefined),
			take: layout?.numberOfProducts,
		};
	}, [filters, layout?.defaultSorting, layout?.numberOfProducts, layout?.sortingDirection]);

	return (
		<QueryErrorResetBoundary>
			{({ reset }) => (
				<FilterContextProvider
					styling={{
						button: {
							variant: 'secondary',
							size: 'sm',
						},
					}}
					storeInUrl
					urlParam={`${builderBlock.id}-filter`}
				>
					<ErrorBoundary FallBack={PLPErrorBoundary} resetError={reset}>
						<Suspense
							fallback={
								<PLPFetching
									noPadding
									showFilters={layout?.showFilters}
									showCategories={layout?.showCategories}
								/>
							}
						>
							<CmsPlpContextProvider
								query={query}
								id={builderBlock.id}
								paginationEnabled={layout?.allowPagination}
							>
								<ProductList.Layout>
									{layout?.showCategories && !isMobile && <ProductList.Categories.Menu />}
									<ProductList.Wrapper>
										<ProductList.FilterHeader
											showFilters={layout?.showFilters}
											showCategories={layout?.showCategories}
										/>

										<ProductList />
									</ProductList.Wrapper>
								</ProductList.Layout>
							</CmsPlpContextProvider>
						</Suspense>
					</ErrorBoundary>
				</FilterContextProvider>
			)}
		</QueryErrorResetBoundary>
	);
};
