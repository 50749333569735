import React from 'react';
import { SupportedCssUnits } from 'types/cssUnits';
import { breakpoints } from 'helpers/cssVariables';
import { DefaultImage } from './DefaultImage';

interface ImageProps {
	className?: string;
	desktopSrc?: string;
	desktopSrc2x?: string;
	mobileSrc?: string;
	mobileSrc2x?: string;
	altText: string;
	isLazy?: boolean;
	width?: SupportedCssUnits;
	height?: SupportedCssUnits;
	isThumbnail?: boolean;
	isSmallImage?: boolean;
	isSkeleton?: boolean;
}

/**
 * Generic image component with optional additional responsive and retina assets
 */
export const Image: React.FunctionComponent<ImageProps> = ({
	className,
	desktopSrc,
	desktopSrc2x,
	mobileSrc,
	mobileSrc2x,
	altText,
	isLazy = true,
	width,
	isSkeleton,
	height,
	isThumbnail = false,
	isSmallImage = isThumbnail,
}) => {
	const layoutSwitch = breakpoints.md;

	if (!desktopSrc) {
		return (
			<DefaultImage
				className={className}
				isSkeleton={isSkeleton}
				isThumbnail={isThumbnail}
				isSmallImage={isSmallImage}
			/>
		);
	}

	return (
		<picture className={className}>
			{desktopSrc2x && (
				<source
					srcSet={`${desktopSrc}, ${desktopSrc2x || desktopSrc} 2x`}
					media={`(min-width: ${layoutSwitch}px)`}
				/>
			)}
			{mobileSrc && <source srcSet={`${mobileSrc}, ${mobileSrc2x || mobileSrc} 2x`} />}
			<img src={desktopSrc} alt={altText} width={width} height={height} loading={isLazy ? 'lazy' : undefined} />
		</picture>
	);
};
