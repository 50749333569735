import React from 'react';
import { CheckBoxFilterContent } from '../../../Filters/CheckBoxFilter/CheckBoxFilterContent';
import { RangeFilterContent } from '../../../Filters/RangeFilter/RangeFilterContent';
import { SearchableFilterContent } from '../../../Filters/SearchableFilter/SearchableFilterContent';
import { SearchableFilterHeader } from '../../../Filters/SearchableFilter/SearchableFilterHeader';
import { SingleFilterContent } from '../../../Filters/SingleFilter/SingleFilterContent';
import { FilterTypes } from '../../../types/FilterState';
import { FilterTypesEnum } from '../../../types/FilterTypes';

export interface FilterItemContentProps {
	filter: FilterTypes;
}

export const FilterItemContent: React.FunctionComponent<FilterItemContentProps> = ({ filter }) => {
	if (filter.type === FilterTypesEnum.Checkbox) {
		if ('search' in filter) {
			return (
				<>
					<SearchableFilterHeader filter={filter} />
					<SearchableFilterContent filter={filter} />
				</>
			);
		}
		return <CheckBoxFilterContent filter={filter} />;
	}
	if (filter.type === FilterTypesEnum.Range) {
		return <RangeFilterContent filter={filter} />;
	}
	if (filter.type === FilterTypesEnum.Single) {
		return <SingleFilterContent filter={filter} />;
	}

	return <></>;
};
