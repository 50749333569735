import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/shared';
import Toggle from 'components/shared/Toggle';
import { useShowBasketTotals } from 'components/shared/hooks/useShowBasketTotals';
import styles from './ToggleBasketTotals.module.scss';

export interface ToggleShowBasketTotalsProps {}

const ToggleShowBasketTotals: React.FunctionComponent<ToggleShowBasketTotalsProps> = () => {
	const { showBasketTotals, handleClick } = useShowBasketTotals();
	const { data: translations } = useTranslationQuery();
	return (
		<Tooltip placement="top">
			<TooltipTrigger className={classNames(styles.showBasketTotals)}>
				<span className={styles.wrapper}>
					{translations?.myProfile.showBasketTotals}
					<Toggle value={showBasketTotals} onChange={handleClick} variant="secondary" />
				</span>
			</TooltipTrigger>
			<TooltipContent className={styles.tooltipText}>
				{translations?.myProfile.showBasketTotalsTooltip}
			</TooltipContent>
		</Tooltip>
	);
};

export default ToggleShowBasketTotals;
