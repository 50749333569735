import React, { FC, useRef, useState } from 'react';
import { createContext, useContext } from 'react';
import { isEmpty } from 'lodash';
import { useCommitProductBasketMutation, useProductBasketQuery } from '../api/productBasket';
import { useTranslationQuery } from 'api/translations';
import { useProductDetailContext } from 'components/features/ProductDetail/context/ProductDetailContext';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import {
	AssortmentType,
	BasketFilterType,
	BasketGroupingTypeRequest,
	BasketLineQuantityResponse,
	BasketLineRequest,
	BasketResponse,
	NewProductBasketResponse,
	ProductBasketRequest,
} from 'generated/data-contracts';
import { AppWebViews, isScannerApp, messageToApp } from 'helpers/app';
import { useViewportSize } from 'helpers/useViewportSize';
import { useBasketLinesUpdate } from '../hooks/useBasketLinesUpdate';
import { NOW_DELIVERY_DATE_VALUE } from '../shared/basketTypes';

export type FreeAssortmentInfo = {
	freeAssortmentSizes: string[];
	is2Dimensional: boolean;
	isOnFreeAssortmentView: boolean;
};

export type ProductBasketContextReturnType = {
	basketRef: React.RefObject<HTMLDivElement>;
	productId: string;
	isFetching: boolean;
	isSuccess: boolean;
	shouldUseAvailability: boolean;
	basket: BasketResponse;
	preBasket: ProductBasketRequest;
	isSDO: boolean;
	freeAssortmentInfo: FreeAssortmentInfo;
	isSavingBasket: boolean;
	totals: {
		committedQuantity: number;
		uncommittedQuantity: number;
		uncommittedPrice: string;
	};
	actions: {
		onQuantityUpdate: (newBasketLine: BasketLineRequest) => Promise<BasketLineQuantityResponse | void>;
		onAddNote: (note: string, key: string) => void;
		clearPreBasket: () => void;
		saveBasket: () => Promise<NewProductBasketResponse | void>;
		bringBasketIntoView: () => void;
	};
};

type ProductBasketProviderProps = {
	productId: string;
	children: React.ReactNode;
};

const ProductBasketContext = createContext<ProductBasketContextReturnType | undefined>(undefined);

export const BasketContextProvider: FC<ProductBasketProviderProps> = ({
	productId,
	children,
}: ProductBasketProviderProps) => {
	const { notificationActions } = useNotificationContext();
	const { data: translations } = useTranslationQuery();
	const { isMobile } = useViewportSize();

	const basketRef = useRef<HTMLDivElement>(null);

	const commitBasket = useCommitProductBasketMutation();
	const {
		deliveryDates: { sanitizedDeliveryDate: deliveryDate },
		assortmentView: { selectedView },
	} = useProductDetailContext();

	const [allowedShipTos, setAllowedShipTos] = useState<string[] | undefined>(undefined);
	const basketQuery: ProductBasketRequest = React.useMemo(
		() => ({
			familyId: productId,
			basketLines: [],
			basketDeliveryDate: deliveryDate,
			groupingTypeRequest: BasketGroupingTypeRequest.Non,
			includeShipToGrouping: isMobile,
			shippingFilter: BasketFilterType.All,
		}),
		[deliveryDate, isMobile, productId],
	);

	const updateBasket = useBasketLinesUpdate(basketQuery, { allowedShipTos });

	const { data, isFetching, isSuccess } = useProductBasketQuery(basketQuery);

	const upsertBasketLine = async (newBasketLine: BasketLineRequest): Promise<BasketLineQuantityResponse | void> => {
		return updateBasket.addBasketLine(newBasketLine);
	};

	const saveBasket = async (): Promise<NewProductBasketResponse | void> => {
		if (updateBasket.prebasketLines.length === 0 && Object.keys(updateBasket.prebasketNotes).length === 0) return;

		commitBasket.mutate(
			{
				request: {
					...basketQuery,
					basketLines: updateBasket.prebasketLines,
					styleNotes: updateBasket.prebasketNotes,
				},
			},
			{
				onSuccess: ({ data }) => {
					notificationActions.addNotification({
						type: AlertTypes.SUCCESS,
						children: `${data.miniBasketQuantity} ${translations?.preBasket.itemsUpdatedInBasket}`,
					});
					updateBasket.clearBasketLines();

					if (isScannerApp && navigator.userAgent.includes(AppWebViews.SCAN)) {
						messageToApp({ type: 'returnToScan' });
					}
				},
			},
		);
	};

	const bringBasketIntoView = (): void => {
		basketRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	React.useEffect(() => {
		if (!data?.basketContents.basketShipTos) return;
		setAllowedShipTos(data.basketContents.basketShipTos);
	}, [data?.basketContents.basketShipTos]);
	if (data === undefined) {
		return null;
	}

	const basket = data;

	const isSDO = basket.basketContents.basketShipTos.length === 1;

	const freeAssortmentSizes = basket.basketContents.lookups.families[productId]?.freeAssortmentSizes ?? [];
	const isOnFreeAssortmentView = selectedView === AssortmentType.FreeAssortments;
	const is2Dimensional = Object.values(basket.basketContents.lookups.masters).some((r) => {
		return r.variantsGroupedByLength.some((r) => !isEmpty(r.length));
	});

	const basketContext: ProductBasketContextReturnType = {
		basketRef,
		productId,
		isFetching,
		shouldUseAvailability: deliveryDate === NOW_DELIVERY_DATE_VALUE,
		isSuccess,
		totals: {
			committedQuantity: basket.miniBasketQuantity,
			uncommittedQuantity: basket.uncommittedQuantity,
			uncommittedPrice: basket.totalPrice,
		},
		basket: basket.basketContents,
		preBasket: {
			...basketQuery,
			basketLines: updateBasket.prebasketLines,
			styleNotes: updateBasket.prebasketNotes,
		},
		isSDO,
		freeAssortmentInfo: {
			freeAssortmentSizes,
			is2Dimensional,
			isOnFreeAssortmentView,
		},
		actions: {
			onQuantityUpdate: upsertBasketLine,
			clearPreBasket: updateBasket.clearBasketLines,
			onAddNote: (note, key) => {
				updateBasket.addBasketNote(key, note);
			},
			saveBasket,
			bringBasketIntoView,
		},
		isSavingBasket: commitBasket.isPending,
	};
	return <ProductBasketContext.Provider value={basketContext}>{children}</ProductBasketContext.Provider>;
};

export const useProductBasketContext = (): ProductBasketContextReturnType => {
	const context = useContext(ProductBasketContext);

	if (!context) {
		throw new Error(`useProductBasketContext must be used within a ProductBasketContextProvider.`);
	}
	return context;
};
