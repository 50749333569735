import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Badge } from 'components/shared';
import { Button } from 'components/shared/Button';
import { DrawerBody, DrawerFooter, DrawerHeader, DrawerLevel } from 'components/shared/Drawer';
import { Icon } from 'components/shared/Icon';
import { SelectedItemsSection } from '../../Filters/shared/SelectedItemsSection';
import { useFilterContext } from '../../context/FilterContext';
import { FilterTypes } from '../../types/FilterState';
import { SearchableCheckboxFilterType } from '../../types/FilterTypes';
import styles from './FilterDrawerItem.module.scss';
import { FilterItemContent } from './FilterItemContent';

export interface FilterDrawerItemProps {
	filter: FilterTypes;
}

export const FilterDrawerItem: React.FunctionComponent<FilterDrawerItemProps> = ({ filter }) => {
	const { commitFilters, closeWithoutSaving, getActiveCount } = useFilterContext();

	const footerRef = React.useRef<HTMLElement>(null);
	const [isActive, setIsActive] = React.useState(false);
	const { data: translations } = useTranslationQuery();

	const onClick = () => setIsActive(true);
	const onCancel = () => {
		closeWithoutSaving();
		setIsActive(false);
	};
	const onClose = () => {
		closeWithoutSaving();
		setIsActive(false);
	};
	const onSubmit = () => {
		commitFilters();
		setIsActive(false);
		if ((filter as SearchableCheckboxFilterType).search) {
			(filter as SearchableCheckboxFilterType).search.clearSearch();
		}
	};
	const isSearchableFilter = 'search' in filter;
	const activeNumber = getActiveCount(filter.type, filter.id);
	return (
		<>
			<Button hasNoStyles className={styles.button} onClick={onClick}>
				<span className={styles.label}>{filter.label}</span>
				<Badge value={activeNumber} />
				<Icon name="chevronDown" size="md" rotate={270} />
			</Button>
			{isActive && (
				<DrawerLevel isActive={isActive}>
					<DrawerHeader handleBack={onClose} showBackButton handleClose={onClose}>
						<span className={styles.title}>{filter.label}</span>
					</DrawerHeader>
					<DrawerBody>
						<FilterItemContent filter={filter} />
					</DrawerBody>
					{isSearchableFilter && <SelectedItemsSection filterId={filter.id} footerRef={footerRef} />}
					<DrawerFooter className={styles.footer} ref={footerRef}>
						<Button variant="outline" onClick={onCancel}>
							{translations?.shared.cancel}
						</Button>
						<Button variant="dark" onClick={onSubmit}>
							{translations?.shared.apply}
						</Button>
					</DrawerFooter>
				</DrawerLevel>
			)}
		</>
	);
};
