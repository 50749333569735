import B_BS from './BESTSELLER.svg';
import B_04ADD from './Jack & Jones/ADDITIONALS.svg';
import B_04FOOT from './Jack & Jones/Footwear.svg';
import B_04JJR from './Jack & Jones/JJR.svg';
import B_04JJXX from './Jack & Jones/JJXX.svg';
import B_04 from './Jack & Jones/Jack & Jones.svg';
import B_04JJ from './Jack & Jones/Jack & Jones.svg';
import B_04JUNIOR from './Jack & Jones/Junior.svg';
import B_04MINI from './Jack & Jones/Mini.svg';
import B_04PLUS from './Jack & Jones/Plus.svg';
import B_04BLANKS from './Jack & Jones/Produkt Blanks.svg';
import B_04PROD from './Jack & Jones/Produkt.svg';
import B_04RDD from './Jack & Jones/RDD.svg';
import B_12CURVE from './MAMALICIOUS/CURVE.svg';
import B_12 from './MAMALICIOUS/MAMALICIOUS.svg';
import B_12MAMA from './MAMALICIOUS/MAMALICIOUS.svg';
import B_03LMTD from './Name It/LMTD.svg';
import B_03LILA from "./Name It/Lil' Atelier.svg";
import B_03 from './Name It/Name It.svg';
import B_03NAMEIT from './Name It/Name It.svg';
import B_18 from './Noisy may/Noisy May.svg';
import B_18CURVE from './Noisy may/curve.svg';
import B_09 from './OBJECT.svg';
import B_11JUNIOR from './ONLY & SONS/ONLY & SONS Junior.svg';
import B_11 from './ONLY & SONS/ONLY & SONS.svg';
import B_11ONLYSONS from './ONLY & SONS/ONLY & SONS.svg';
import B_02JDY from './ONLY/JDY.svg';
import B_02KIDS from './ONLY/Kids ONLY.svg';
import B_02LSU from './ONLY/La Strada Unica.svg';
import B_02NN from './ONLY/Neon & Nylon.svg';
import B_02CARMA from './ONLY/ONLY Carmakoma.svg';
import B_02MATERN from './ONLY/ONLY Maternity.svg';
import B_02PLAY from './ONLY/ONLY Play.svg';
import B_02SHOES from './ONLY/ONLY Shoes.svg';
import B_02 from './ONLY/ONLY.svg';
import B_02ONLY from './ONLY/ONLY.svg';
import B_08KIDS from './PIECES/Little Pieces.svg';
import B_08 from './PIECES/PIECES.svg';
import B_08PIECES from './PIECES/PIECES.svg';
import B_07ANNARR from './Selected/Annarr.svg';
import B_07FEMME from './Selected/Selected Femme.svg';
import B_07HOMME from './Selected/Selected Homme.svg';
import B_07 from './Selected/Selected.svg';
import B_TEST from './Test/TestBrand.svg';
import B_TESTSUB from './Test/TestSubbrand.svg';
import B_05AWARE from './VERO MODA/AWARE.svg';
import B_05INCL from './VERO MODA/Included.svg';
import B_05SNEW from './VERO MODA/SOMETHINGNEW.svg';
import B_05ACC from './VERO MODA/VERO MODA Accessories.svg';
import B_05CURVE from './VERO MODA/VERO MODA Curve.svg';
import B_05GIRL from './VERO MODA/VERO MODA Girl.svg';
import B_05 from './VERO MODA/VERO MODA.svg';
import B_05VMODA from './VERO MODA/VERO MODA.svg';
import B_05MATERN from './VERO MODA/VEROMODA Maternity.svg';
import B_01EVOKED from './VILA/EVOKED.svg';
import B_01ROUGE from './VILA/ROUGE.svg';
import B_01 from './VILA/VILA.svg';
import B_01VILA from './VILA/VILA.svg';
import B_14 from './Y.A.S.svg';
import B_22 from './TopShop/TopShop.svg';

export const BrandLogos = {
	B_BS,
	B_04ADD,
	B_04FOOT,
	B_04JJR,
	B_04JJXX,
	B_04,
	B_04JJ,
	B_04JUNIOR,
	B_04MINI,
	B_04PLUS,
	B_04BLANKS,
	B_04PROD,
	B_04RDD,
	B_12CURVE,
	B_12,
	B_12MAMA,
	B_03LMTD,
	B_03LILA,
	B_03,
	B_03NAMEIT,
	B_18,
	B_18CURVE,
	B_09,
	B_11JUNIOR,
	B_11,
	B_11ONLYSONS,
	B_02JDY,
	B_02KIDS,
	B_02LSU,
	B_02NN,
	B_02CARMA,
	B_02MATERN,
	B_02PLAY,
	B_02SHOES,
	B_02,
	B_02ONLY,
	B_08KIDS,
	B_08,
	B_08PIECES,
	B_07ANNARR,
	B_07FEMME,
	B_07HOMME,
	B_07,
	B_TEST,
	B_TESTSUB,
	B_05AWARE,
	B_05INCL,
	B_05SNEW,
	B_05ACC,
	B_05CURVE,
	B_05GIRL,
	B_05,
	B_05VMODA,
	B_05MATERN,
	B_01EVOKED,
	B_01ROUGE,
	B_01,
	B_01VILA,
	B_14,
	B_22,
};
