import React from 'react';
import { useLocation } from 'react-router-dom';
import { BuilderBlocks, BuilderElement } from '@builder.io/react';
import { useSearchParams } from 'library/routing';
import { NewTabs, NewTabPanel, NewTabsProvider } from 'components/shared';
import styles from './Tabs.module.scss';

type BuilderBlockType = BuilderElement;

export interface TabProps {
	label: string;
	content: BuilderBlockType[];
}

export interface TabsProps {
	tabs?: TabProps[];
	builderBlock: BuilderBlockType;
}

/**
 * CMS Tabs component, used for showing CMS content in tabs
 */
export const Tabs: React.FC<TabsProps> = ({ tabs, builderBlock }) => {
	const URL_PARAM = `${builderBlock.id}-tab`;
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const [currentlySelectedTab, setCurrentlySelectedTab] = React.useState(() => {
		const tabFromURL = searchParams.get(URL_PARAM);
		return tabFromURL ?? `0`;
	});

	React.useEffect(() => {
		setSearchParams(
			(prev) => {
				if (!currentlySelectedTab) prev.delete(URL_PARAM);
				else prev.set(URL_PARAM, currentlySelectedTab);
				return prev;
			},
			{
				replace: true,
				state: location.state,
			},
		);
	}, [URL_PARAM, currentlySelectedTab, location.state, setSearchParams]);

	if (!tabs) return null;
	return (
		<NewTabsProvider
			tabList={tabs.map(({ label }, index) => ({
				id: `${index}`,
				label,
			}))}
			initiallySelectedTab={currentlySelectedTab}
		>
			<NewTabs
				className={styles.tabs}
				tabListClassName={styles.tabs}
				tabClassName={styles.tab}
				indicatorClassName={styles.indicator}
			/>
			{tabs.map((tab, index) => (
				<NewTabPanel tabId={`${index}`} key={tab.label}>
					{({ isActive }) => {
						if (isActive) setCurrentlySelectedTab(`${index}`);
						return (
							<BuilderBlocks
								key={index}
								parentElementId={builderBlock && builderBlock.id}
								dataPath={`component.options.tabs.${index}.content`}
								child
								blocks={tab.content}
							/>
						);
					}}
				</NewTabPanel>
			))}
		</NewTabsProvider>
	);
};
