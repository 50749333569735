import React from 'react';
import classNames from 'classnames';
import { stripAllWhitespace } from 'helpers/stringHelpers';
import styles from './NewTabs.module.scss';
import { useNewTabsState } from './NewTabsContext';

export * from './NewTabsContext';
export * from './NewTabPanel';
interface NewTabsProps {
	className?: string;
	tabListClassName?: string;
	tabClassName?: string;
	indicatorClassName?: string;
}

export const NewTabs: React.FunctionComponent<NewTabsProps> = ({
	className,
	tabListClassName,
	tabClassName,
	indicatorClassName,
}) => {
	const { tabList, animationTime, handleKeyPress, handleSelectTab, selectedTab, tabRefs, indicatorRef } =
		useNewTabsState();

	return (
		<div className={className}>
			{/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
			<div
				role="tablist"
				className={classNames(tabListClassName, styles.tabList, 'u-scroll-bar-hidden')}
				onKeyDown={handleKeyPress}
				style={
					{
						'--num-tabs': tabList.length,
						'--indicator-transition-time': `${animationTime}ms`,
					} as React.CSSProperties
				}
			>
				{tabList.map(({ label, id }, index) => {
					const isSelected = selectedTab === id;
					const strippedTabName = stripAllWhitespace(label);

					return (
						<button
							className={classNames(tabClassName, styles.tab, '.u-ellipsis')}
							role="tab"
							aria-selected={isSelected}
							aria-controls={`${strippedTabName}-tab`}
							id={`tab-${id}`}
							data-label={label}
							tabIndex={!isSelected ? -1 : undefined}
							onClick={(): void => handleSelectTab(id)}
							ref={(ref): HTMLButtonElement | null => (tabRefs.current[index] = ref)}
							key={strippedTabName + id}
						>
							{label}
						</button>
					);
				})}

				<div
					ref={indicatorRef}
					className={classNames(indicatorClassName, styles.indicator)}
					aria-hidden="true"
				/>
			</div>
		</div>
	);
};
