import React, { FC } from 'react';
import classNames from 'classnames';
import { formatTranslation } from 'helpers';
import { useLayoutQuery } from 'api/layout';
import { Roles } from 'api/roles';
import { useTranslationQuery } from 'api/translations';
import styles from './NoResults.module.scss';

interface NoResultsProps {
	searchText: string;
	noResultsMessage: string;
	className?: string;
}

const NoResults: FC<NoResultsProps> = ({ className, searchText, noResultsMessage }) => {
	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();

	// Function to display appropriate message when no accounts are found
	const displayNoAccountsMessage = (): string | undefined => {
		// If there's a search text, return the "no accounts for search" message
		if (searchText) {
			return noResultsMessage;
		}

		// If the user is a salesperson, return a specific message with a hint to edit accounts
		if (layout?.user?.userRole === Roles.SalesPerson) {
			return formatTranslation(translations?.accountSelector.noLinkedAccountsForSalesPerson, {
				0: translations?.accountSelector.editAccounts.manageYourAccounts,
			});
		}

		// Default case: return the general "no accounts for search" message
		return noResultsMessage;
	};

	return (
		<li className={classNames(styles.noResults, className)}>
			<span className={styles.noResultsHeading}>
				{searchText
					? formatTranslation(translations?.productList.noResultsFor, {
							0: searchText,
					  })
					: translations?.productList.noResults}
			</span>
			<p>{displayNoAccountsMessage()}</p>
		</li>
	);
};

export default NoResults;
