import React from 'react';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import {
	CheckboxFilterValue,
	FilterTypesEnum,
	SingleFilterType,
} from 'components/shared/FilterList/V2/types/FilterTypes';
import { MegaMenuItem } from 'generated/data-contracts';

export const ALL_CATEGORIES_KEYWORD = 'all';
const convertMegaMenuItemToCheckboxFilter = (menuItem: MegaMenuItem): CheckboxFilterValue => ({
	label: menuItem.name,
	value: menuItem.id ?? ALL_CATEGORIES_KEYWORD,
	isSelected: false,
});
export const CATEGORIES_FILTER_KEY = 'category';
const useCategoriesFilter = (): SingleFilterType => {
	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();
	if (!translations || !layout) throw new Error('No translations');
	const options: CheckboxFilterValue[] = React.useMemo(
		(): CheckboxFilterValue[] =>
			layout.megaMenu.children.flatMap((menuItem) => [
				convertMegaMenuItemToCheckboxFilter(menuItem),
				...menuItem.children.map(convertMegaMenuItemToCheckboxFilter),
			]),
		[layout.megaMenu.children],
	);

	const filter = React.useMemo(
		(): SingleFilterType => ({
			id: CATEGORIES_FILTER_KEY,
			label: translations.productList.categories,
			type: FilterTypesEnum.Single,
			values: options,
		}),
		[options, translations.productList.categories],
	);

	return filter;
};

export default useCategoriesFilter;
