import React from 'react';
import { FetchNextPageOptions, FetchPreviousPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { ProductSearchQuery } from '../api/products';
import { MegaMenuItem, ProductListResponse } from 'generated/data-contracts';
import { UsePlpSortByReturnType } from '../hooks/usePlpSortingOptions';

export interface PlpPagination {
	paginationStrategy: 'page' | 'infinite' | 'disabled';
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	isFetchingNextPage: boolean;
	isFetchingPreviousPage: boolean;
	fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<ProductListResponse, Error>>;
	fetchPreviousPage: (
		options?: FetchPreviousPageOptions,
	) => Promise<InfiniteQueryObserverResult<ProductListResponse, Error>>;
}

export interface PlpContextType {
	products: ProductListResponse;
	numberOfColumns: number;
	isStale: boolean;
	isFetching: boolean;
	pagination: PlpPagination;
	sort: UsePlpSortByReturnType;
	updatePageParamForIndex?: (index: number) => void;
	selectedCategory: {
		label: string;
		id: string | null;
	};
	selectCategory: (item: MegaMenuItem) => void;
	searchQuery?: ProductSearchQuery;
	availableCategories: string[];
}

export const PlpContext = React.createContext<PlpContextType | null>(null);

export const usePlpContext = (): PlpContextType => {
	const plpContext = React.useContext(PlpContext);

	if (!plpContext) {
		throw new Error('usePlpContext must be used within a PlpContextProvider');
	}

	return plpContext;
};
