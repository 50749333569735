import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Product as ProductApi } from 'generated/Product';
import { QuickSearchResponse } from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import { useAppStore } from 'store/appStore';
import { queryKeys, setHeaders } from './apiConfig';
import { useTranslationQuery } from './translations';

export const DefaultPageSize = 36;

export const useQuickSearch = (phrase): UseQueryResult<QuickSearchResponse, unknown> => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useQuery({
		queryKey: queryKeys.product.quickSearch(segmentationId, phrase).queryKey,
		queryFn: async ({ signal }): Promise<QuickSearchResponse | void> => {
			const quickSearchApi = new ProductApi({ baseApiParams: { headers: setHeaders() } });
			const response = await quickSearchApi.productQuicksearchList({ segmentationId, phrase }, { signal });
			if (!response.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.shared.layoutErrorMsg, {}),
					type: AlertTypes.DANGER,
				});
			}

			return response.data;
		},
		retry: false,
		enabled: !isEmpty(phrase),
	});
};
