/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CustomerResponse,
  CustomerSearchRequest,
  CustomerSearchResponse,
  CustomerUpsertRequest,
  HttpValidationProblemDetails,
  ListRoleResponse,
  ProblemDetails,
  WebsiteUserId,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Customer<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Customer
   * @name CustomerListrolesList
   * @request GET:/api/customer/listroles
   * @secure
   */
  customerListrolesList = (params: RequestParams = {}) =>
    this.request<ListRoleResponse, void | ProblemDetails>({
      path: `/api/customer/listroles`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Customer
   * @name CustomerSearchCreate
   * @request POST:/api/customer/search
   * @secure
   */
  customerSearchCreate = (data: CustomerSearchRequest, params: RequestParams = {}) =>
    this.request<CustomerSearchResponse, ProblemDetails | void>({
      path: `/api/customer/search`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Customer
   * @name CustomerUpsertCreate
   * @request POST:/api/customer/upsert
   * @secure
   */
  customerUpsertCreate = (data: CustomerUpsertRequest, params: RequestParams = {}) =>
    this.request<CustomerResponse, HttpValidationProblemDetails | ProblemDetails | void>({
      path: `/api/customer/upsert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Customer
   * @name CustomerImpersonateCreate
   * @request POST:/api/customer/impersonate/{id}
   * @secure
   */
  customerImpersonateCreate = (id: WebsiteUserId, params: RequestParams = {}) =>
    this.request<any, void | ProblemDetails>({
      path: `/api/customer/impersonate/${id}`,
      method: "POST",
      secure: true,
      ...params,
    });
}
