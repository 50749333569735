import React, { useDeferredValue } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { useCmsProductSearchQuery } from '../../api/products';
import { useLayoutQuery } from 'api/layout';
import { ProductBundleQuery } from 'api/product';
import { useFilterContext } from 'components/shared/FilterList/V2/context/FilterContext';
import { convertFilterResponseToCheckboxFilter } from 'components/shared/FilterList/V2/helpers/convertFilterResponseToCheckboxFilter';
import { FilterTypes } from 'components/shared/FilterList/V2/types/FilterState';
import { FilterTypesEnum } from 'components/shared/FilterList/V2/types/FilterTypes';
import { MegaMenuItem, PageType, ProductFilter, ProductSearchRequest } from 'generated/data-contracts';
import { PLPUrlParamKeys } from '../../constants/PLPUrlParams';
import useCategoriesFilter, { ALL_CATEGORIES_KEYWORD } from '../../hooks/useCategoriesFilter';
import { usePlpSortBy } from '../../hooks/usePlpSortingOptions';
import { PlpContext, PlpContextType } from '../PlpContext';

export const CmsPlpContextProvider: React.FunctionComponent<{
	children: ((props: PlpContextType) => React.ReactNode) | React.ReactNode;
	query: ProductBundleQuery;
	id?: string;
	paginationEnabled?: boolean;
}> = ({ children, query, id: builderId, paginationEnabled = true }) => {
	const { data: layout } = useLayoutQuery();

	const sort = usePlpSortBy({
		defaultSortDirection: query.sortDirection,
		defaultSortOption: query.sortBy,
		storeInUrl: true,
		sortByUrlParam: `${builderId}-${PLPUrlParamKeys.SortBy}`,
		sortDirectionUrlParam: `${builderId}-${PLPUrlParamKeys.SortDirection}`,
	});

	const {
		selectedFilters,
		setAvailableFilters,
		singleFilterActions: { onChangeSingleItem, onRemoveSingleItem },
	} = useFilterContext();

	const selectCategory = (item: MegaMenuItem) => {
		if (item.route?.productCategoryId) {
			onChangeSingleItem(
				PLPUrlParamKeys.Category,
				{
					value: item.route.productCategoryId,
					label: item.name,
					isSelected: true,
				},
				true,
			);
		} else {
			onRemoveSingleItem(PLPUrlParamKeys.Category);
		}
	};

	const categoryFilter = useCategoriesFilter();
	const category = selectedFilters[FilterTypesEnum.Single].get(PLPUrlParamKeys.Category);

	const selectedCategory = React.useMemo(() => {
		if (!category) {
			return {
				id: null,
				label:
					layout?.megaMenu.children.find((r) => r.route?.pageType === PageType.All)?.name ??
					ALL_CATEGORIES_KEYWORD,
			};
		}
		return {
			id: category.value,
			label: category.label,
		};
	}, [layout?.megaMenu.children, category]);

	const productFilters = React.useMemo((): ProductFilter[] => {
		return [
			...Array.from(selectedFilters[FilterTypesEnum.Checkbox].entries()).map(([key, value]): ProductFilter => {
				return {
					attributeKey: key,
					attributeValues: Array.from(value.keys()),
				};
			}),
		];
	}, [selectedFilters]);
	const searchQuery = React.useMemo((): ProductSearchRequest => {
		return {
			skip: 0,
			sortBy: sort.sortBy,
			sortDirection: sort.sortDirection,
			take: query.take || 60,
			productFilters,
			phrase: query.phrase,
			productCategoryId: category?.value,
			customerGroupIds: query.customerGroupIds,
			productLineIds: query.productLineIds,
			collectionIds: query.collectionIds,
			predefinedProductFilters: query.preDefinedProductFilters,
			predefinedProductCategoryIds: Array.from(query.preDefinedProductCategoryIds ?? []).concat(
				query.preDefinedProductSubcategoryIds ?? [],
			),

			productCatalogues: Array.from(query.productCatalogues ?? []).concat(query.productSubcatalogues ?? []),
		};
	}, [
		category?.value,
		productFilters,
		query.collectionIds,
		query.customerGroupIds,
		query.phrase,
		query.preDefinedProductCategoryIds,
		query.preDefinedProductFilters,
		query.preDefinedProductSubcategoryIds,
		query.productCatalogues,
		query.productLineIds,
		query.productSubcatalogues,
		query.take,
		sort.sortBy,
		sort.sortDirection,
	]);

	const deferredQuery = useDeferredValue(searchQuery);
	const numberOfColumns = 6;
	const isStale = !isEqual(deferredQuery, searchQuery);
	const {
		data: products,
		fetchNextPage,
		fetchPreviousPage,
		hasNextPage,
		hasPreviousPage,
		isFetchingNextPage,
		isFetchingPreviousPage,
		isFetching,
	} = useCmsProductSearchQuery(deferredQuery);

	React.useEffect(() => {
		const filters: FilterTypes[] = new Array<FilterTypes>(categoryFilter).concat(
			products.filters.filter((r) => !isEmpty(r)).map(convertFilterResponseToCheckboxFilter),
		);
		setAvailableFilters(filters);
	}, [categoryFilter, products.filters, setAvailableFilters]);

	const value: PlpContextType = {
		products,
		numberOfColumns,
		isStale,
		isFetching,
		sort,
		pagination: {
			fetchNextPage,
			fetchPreviousPage,
			hasNextPage,
			hasPreviousPage,
			isFetchingNextPage,
			isFetchingPreviousPage,
			paginationStrategy: paginationEnabled ? 'infinite' : 'disabled',
		},
		selectCategory,
		selectedCategory,
		availableCategories: products.productCategoryIds,
	};
	return (
		<PlpContext.Provider value={value}>
			{typeof children === 'function' ? children(value) : children}
		</PlpContext.Provider>
	);
};
