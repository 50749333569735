import React, { FC } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { usePlpContext } from 'components/fragments/Plp/context/PlpContext';
import { Button, DrawerBody, DrawerHeader, DrawerLevel, Icon } from 'components/shared';
import { MegaMenuItem } from 'generated/data-contracts';
import styles from './CategoryDrawerItem.module.scss';

export interface CategoryDrawerItemProps {
	item: MegaMenuItem;
	className?: string;
	handleClose: () => void;
}

export const CategoryDrawerItem: FC<CategoryDrawerItemProps> = ({ item, className, handleClose }) => {
	const { selectCategory, selectedCategory } = usePlpContext();
	const { data: translations } = useTranslationQuery();
	const [isOpen, setIsOpen] = React.useState(false);

	const openDrawer = () => setIsOpen(true);

	const onBack = () => {
		setIsOpen(false);
	};
	return (
		<>
			<div className={styles.item}>
				<Button
					variant="ghost"
					onClick={() => {
						if (!isEmpty(item.children)) {
							return openDrawer();
						}
						selectCategory(item);
						handleClose();
					}}
					className={classNames(className, styles.button, {
						[styles.semiBold]: isEmpty(item.children),
					})}
				>
					{item.name}
					<span className={styles.icons}>
						{selectedCategory.id === item.route?.productCategoryId && (
							<Icon name={'checkMark'} size={'md'} />
						)}
						{!isEmpty(item.children) && <Icon name={'chevronDown'} size={'md'} rotate={-90} />}
					</span>
				</Button>
			</div>
			{isOpen && (
				<DrawerLevel direction="Right" isActive={isOpen}>
					<DrawerHeader
						handleClose={handleClose}
						showBackButton
						handleBack={onBack}
						title={item.name}
						titleSize="md"
						size="sm"
					/>
					<DrawerBody className={styles.mainContent}>
						<div className={styles.item}>
							<Button
								variant="ghost"
								onClick={() => {
									selectCategory(item);
									handleClose();
								}}
								className={classNames(className, styles.button, styles.semiBold)}
							>
								{translations.shared.viewAll}
								{selectedCategory.id === item.route?.productCategoryId && (
									<Icon name={'checkMark'} size={'md'} />
								)}
							</Button>
						</div>
						{item.children.map((child) => {
							return (
								<div className={styles.item} key={child.route?.productCategoryId}>
									<Button
										variant="ghost"
										onClick={() => {
											selectCategory(child);
											handleClose();
										}}
										className={classNames(className, styles.button)}
									>
										{child.name}
										{selectedCategory.id === child.route?.productCategoryId && (
											<Icon name={'checkMark'} size={'md'} />
										)}
									</Button>
								</div>
							);
						})}
					</DrawerBody>
				</DrawerLevel>
			)}
		</>
	);
};
