import React, { FC } from 'react';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { CategoryItem } from '../CategoryItem';
import styles from './CategorySection.module.scss';

export interface CategorySectionProps {}

export const CategorySection: FC<CategorySectionProps> = () => {
	const { data: layout } = useLayoutQuery();

	const { data: translations } = useTranslationQuery();
	if (!layout) return <span />;

	const { megaMenu } = layout;
	return (
		<section className={styles.categoryMenu}>
			<h1 className={styles.categoriesHeader}>{translations?.productList.categories}</h1>
			<ul className={styles.categoryList}>
				{megaMenu.children.map((r) => (
					<CategoryItem item={r} key={r.route?.productCategoryId ?? r.route?.area ?? r.name} />
				))}
			</ul>
		</section>
	);
};
