import React, { FC } from 'react';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Button, Drawer, DrawerBody, DrawerHeader, Icon } from 'components/shared';
import { useFilterContext } from 'components/shared/FilterList/V2/context/FilterContext';
import { CategoryDrawerItem } from './CategoryDrawerItem';

export interface CategoryDrawerProps {
	className?: string;
}

export const CategoryDrawer: FC<CategoryDrawerProps> = ({ className }) => {
	const { data: layout } = useLayoutQuery();
	const { styling } = useFilterContext();

	const { data: translations } = useTranslationQuery();
	const [isOpen, setIsOpen] = React.useState(false);
	if (!layout) return <span />;

	const { megaMenu } = layout;

	const openDrawer = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);

	return (
		<>
			<Button
				onClick={openDrawer}
				className={className}
				size={styling.button.size}
				variant={styling.button.variant}
			>
				<Icon name="category" size="sm" />
				{translations.productList.categories}
			</Button>
			{isOpen && (
				<Drawer onClose={handleClose} direction="Right">
					<DrawerHeader
						handleClose={handleClose}
						title={translations?.productList?.categoryAll}
						titleSize="md"
						size="sm"
					/>
					<DrawerBody>
						{megaMenu.children.map((r) => (
							<CategoryDrawerItem
								item={r}
								key={r.route?.productCategoryId ?? r.route?.area}
								handleClose={handleClose}
							/>
						))}
					</DrawerBody>
				</Drawer>
			)}
		</>
	);
};
