import React, { Component, ReactNode } from 'react';

export interface ErrorFallbackProps {
	error: Error;
	resetError: () => void;
}

interface Props {
	FallBack: ReactNode | React.FC<ErrorFallbackProps>;
	resetError?: () => void;
	children?: ReactNode;
}

type State =
	| {
			hasError: true;
			error: Error;
	  }
	| {
			hasError: false;
			error: null;
	  };

export class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
		error: null,
	};

	public static getDerivedStateFromError(error?: Error): State {
		if (!error) {
			return {
				hasError: false,
				error: null,
			};
		}
		return {
			hasError: true,
			error,
		};
	}

	private resetError = async (): Promise<void> => {
		await this.props.resetError?.();
		this.setState({ hasError: false, error: null });
	};

	public render() {
		if (this.state.hasError) {
			const { FallBack } = this.props;
			if (FallBack instanceof Function) {
				return <FallBack error={this.state.error} resetError={this.resetError} />;
			}

			return FallBack;
		}
		return this.props.children;
	}
}
