import React, { PropsWithChildren } from 'react';
import { useLocalStorage } from './useLocalStorage';

const SHOW_BASKET_TOTALS_NAME = 'showBasketTotals';

interface UseShowBasketTotalsReturnType {
	showBasketTotals: boolean;
	handleClick: (value: boolean) => void;
}

const useShowBasketTotalsState = (): UseShowBasketTotalsReturnType => {
	const [showBasketTotals, setShowBasketTotals] = useLocalStorage<boolean>(SHOW_BASKET_TOTALS_NAME, false);

	return {
		showBasketTotals,
		handleClick: setShowBasketTotals,
	};
};

const ShowBasketTotalsContext = React.createContext<UseShowBasketTotalsReturnType | null>(null);

export const ShowBasketTotalsProvider: React.FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
	const value = useShowBasketTotalsState();

	return <ShowBasketTotalsContext.Provider value={value}>{children}</ShowBasketTotalsContext.Provider>;
};

export const useShowBasketTotals = (): UseShowBasketTotalsReturnType => {
	const context = React.useContext(ShowBasketTotalsContext);

	if (!context) {
		throw new Error('useShowBasketTotals must be used within a ShowBasketTotalsProvider');
	}

	return context;
};
