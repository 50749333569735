import React from 'react';
import classNames from 'classnames';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { useFilterContext } from '../../../context/FilterContext';
import { FilterTypesEnum, SingleFilterType } from '../../../types/FilterTypes';
import styles from './SingleFilterContent.module.scss';

export interface SingleFilterContentProps {
	filter: SingleFilterType;
}

export const SingleFilterContent: React.FunctionComponent<SingleFilterContentProps> = ({ filter }) => {
	const { singleFilterActions, uncommittedFilters } = useFilterContext();
	const currentFilter = uncommittedFilters[FilterTypesEnum.Single].get(filter.id);

	return (
		<ul className={styles.list}>
			{filter.values?.map((item) => {
				const isCurrentItemSelected = singleFilterActions.isSingleSelected(filter.id, item.value);
				const hitCount = item.hitCount ? <span>({item.hitCount})</span> : <></>;
				return (
					<li
						key={`${filter.id}-${item.value}`}
						role="option"
						className={styles.listItem}
						aria-selected={isCurrentItemSelected}
					>
						<Button
							key={`dropdown-${item.label}-${item.value}`}
							variant="ghost"
							className={classNames(styles.option)}
							onClick={() => {
								singleFilterActions.onChangeSingleItem(filter.id, item);
							}}
						>
							{item.label}
							<span className={styles.icons}>
								{currentFilter?.value === item.value && <Icon name="checkMark" size="sm" />}
								{hitCount}
							</span>
						</Button>
					</li>
				);
			})}
		</ul>
	);
};
