export enum PLPUrlParamKeys {
	Category = 'category',
	SearchPhrase = 'phrase',
	PageSize = 'take',
	Page = 'page',
	Filters = 'filters',
	SortBy = 'sortBy',
	SortDirection = 'sortDirection',
}

export const PREDEFINED_PLP_URL_PARAMS = Object.values<string>(PLPUrlParamKeys);
