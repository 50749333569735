// Constant dynamic values expected from the BE
import { useQuery } from '@tanstack/react-query';
import { Customer } from 'generated/Customer';
import { RoleResponse } from 'generated/data-contracts';
import { useAppStore } from 'store/appStore';
import { queryKeys, setHeaders } from './apiConfig';

// FIXME: these values are used for comparisons in Account Manager and Customer Management. Instead, there should be and endpoint in the BE to tell the FE if a user is a Sales Person or a Customer Admin or whatever
export enum Roles {
	Admin = 'ADMIN',
	SalesPerson = 'SALES_PERSON',
	CustomerAdmin = 'CUSTOMER_ADMIN',
	CustomerUser = 'CUSTOMER_USER',
	CustomerViewer = 'CUSTOMER_VIEWER',
}

export type RoleItem = RoleResponse;

export const useCustomerRolesQuery = () => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const api = new Customer({ baseApiParams: { headers: setHeaders() } });
	const queryKey = queryKeys.user.roles(segmentationId).queryKey;
	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		queryFn: async () => {
			const response = await api.customerListrolesList();
			return response.data;
		},
		select: (data): RoleItem[] => data.roles as RoleItem[],
		retry: false,
	});
};

export const isRoleAdmin = (role?: string) => role?.toLowerCase().includes('admin');
