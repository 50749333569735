import React, { PropsWithChildren, RefObject, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useCarouselStore } from '../state/CarouselContext';
import styles from './CarouselItem.module.scss';

export const CarouselItem: React.FunctionComponent<
	PropsWithChildren<{
		className?: string;
		index: number;
		listRef: RefObject<HTMLUListElement | null>;
	}>
> = ({ index, listRef, className, children }) => {
	const {
		slides: { setActiveSlide, activeSlide, intersectionEnabled },
	} = useCarouselStore();

	const ref = useRef<HTMLLIElement | null>(null);

	React.useEffect(() => {
		const list = listRef.current;
		if (!list) return;
		const onScroll = () => {
			const ACTIVE_SLIDE_THRESHOLD = 10;
			const PADDING =
				parseInt(window.getComputedStyle(list).paddingLeft) +
				parseInt(window.getComputedStyle(list).paddingRight);
			const scrollLeft = list?.scrollLeft ?? 0;
			const clientWidth = list?.clientWidth - PADDING;
			const expectedWidth = index * clientWidth;
			const isActiveSlide =
				clientWidth > 0 &&
				scrollLeft >= expectedWidth - ACTIVE_SLIDE_THRESHOLD &&
				scrollLeft <= expectedWidth + ACTIVE_SLIDE_THRESHOLD;

			if (isActiveSlide && activeSlide !== index && intersectionEnabled) {
				setActiveSlide(index, false);
			}
		};
		list.addEventListener('scroll', onScroll);
		return () => list.removeEventListener('scroll', onScroll);
	}, [activeSlide, index, intersectionEnabled, listRef, setActiveSlide]);

	const isActiveSlide = activeSlide === index;

	useEffect(() => {
		if (!ref.current) return;
		if (isActiveSlide) {
			ref.current.removeAttribute('inert');
			return;
		}
		ref.current.setAttribute('inert', 'true');
	}, [isActiveSlide]);

	return (
		<li
			className={classNames(className, styles.slide)}
			ref={ref}
			data-slide-number={index}
			aria-hidden={!isActiveSlide}
		>
			{children}
		</li>
	);
};
