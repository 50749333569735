import React from 'react';
import { FilterTypesEnum, SingleFilterType } from '../../types/FilterTypes';
import { FilterDropdown } from '../shared/FilterDropdown';
import { SingleFilterContent } from './SingleFilterContent';

export interface SingleFilterProps {
	filter: SingleFilterType;
}

export const SingleFilter: React.FunctionComponent<SingleFilterProps> = ({ filter }) => {
	return (
		<FilterDropdown filter={filter} filterType={FilterTypesEnum.Single}>
			<SingleFilterContent filter={filter} />
		</FilterDropdown>
	);
};
