import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { SortDropdown } from 'components/fragments/SortDropdown';
import { FilterList } from 'components/shared';
import { ActiveFilterList } from 'components/shared/FilterList/V2/ActiveFilterList';
import { FilterDrawer } from 'components/shared/FilterList/V2/FilterDrawer';
import { useFilterContext } from 'components/shared/FilterList/V2/context/FilterContext';
import { FilterTypesEnum } from 'components/shared/FilterList/V2/types/FilterTypes';
import { useViewportSize } from 'helpers/useViewportSize';
import { PLPUrlParamKeys } from '../../constants/PLPUrlParams';
import { usePlpContext } from '../../context/PlpContext';
import { Categories } from '../CategoryMenu';
import styles from './ProductListHeader.module.scss';

export interface ProductListHeaderProps {
	showCategories?: boolean;
	showFilters?: boolean;
}

export const ProductListHeader: FC<ProductListHeaderProps> = ({ showCategories = true, showFilters = true }) => {
	const { data: translations } = useTranslationQuery();
	const { products, sort } = usePlpContext();
	const { selectedFilters } = useFilterContext();
	const { isMobile } = useViewportSize();
	const numberOfProductsTranslation = `${products?.pagingInformation?.totalNumberOfItems ?? 0} ${translations
		?.productList?.products}`;
	if (!showCategories && !showFilters) return null;
	if (isMobile) {
		return (
			<div className={styles.productListHeader}>
				<div
					className={classNames(styles.mobileFilters, {
						[styles.hasOnly1Item]: !showCategories || !showFilters,
					})}
				>
					{showCategories && <Categories.Drawer />}
					{showFilters && (
						<FilterDrawer
							sort={{
								options: sort.sortOptions,
								onChangeSort: (sortBy, sortDirection) => {
									sort.setSortBy(sortBy);
									sort.setSortDirection(sortDirection);
								},
								sortBy: sort.sortBy,
								sortDirection: sort.sortDirection,
							}}
							numberOfSelectedFilters={Array.from(
								selectedFilters[FilterTypesEnum.Checkbox].values(),
							).reduce((acc, cur) => acc + cur.size, 0)}
						/>
					)}
				</div>
				<div>{numberOfProductsTranslation}</div>
			</div>
		);
	}
	if (!showFilters) return null;
	return (
		<div className={styles.productListHeader}>
			<div className={styles.filtersList}>
				<FilterList.V2 numberOfShownFiltersByDefault={5} exclude={[PLPUrlParamKeys.Category]} />
				<SortDropdown
					defaultOption={sort.sortBy}
					options={sort.sortOptions}
					onChangeSort={(sortBy, sortDirection) => {
						sort.setSortBy(sortBy);
						sort.setSortDirection(sortDirection);
					}}
				/>
			</div>
			<ActiveFilterList exclude={[PLPUrlParamKeys.Category]} />
			<div>{numberOfProductsTranslation}</div>
		</div>
	);
};
