import React from 'react';
import classNames from 'classnames';
import { useImageLookup } from 'api/image';
import DefaultImage from 'assets/images/defaultImage.jpg';
import { Button, Icon, Image, Modal, useModal } from 'components/shared';
import { ZoomableWrapper } from 'components/shared/Image/ZoomableImage';
import { ImageLookupRequestItem } from 'generated/data-contracts';
import styles from './DAMImage.module.scss';

interface ImageResponse {
	size100: { url: string };
	size450: { url: string };
	size1800: { url: string };
	altText: string;
}

const fallBackImage: ImageResponse = {
	size100: { url: DefaultImage },
	size450: { url: DefaultImage },
	size1800: { url: DefaultImage },
	altText: '',
};

interface DAMImageProps {
	children?: React.ReactNode;
	image?: ImageLookupRequestItem;
	altText?: string;
	fitContent?: boolean;
	addZoom?: boolean;
	imageFit?: string;
	imagePosition?: { x?: number; y?: number };
	aspectRatio?: number;
	attributes?: {
		className?: string;
		key?: string;
	};
}

/**
 * CMS Image component, used for creating Image. It overrides Builder's default
 * image component, but source uses DAM images.
 * */
export const DAMImage: React.FunctionComponent<DAMImageProps> = ({
	children,
	image,
	altText,
	imageFit,
	imagePosition,
	fitContent,
	addZoom = false,
	aspectRatio,
	attributes,
}) => {
	const images = image
		? [{ fileId: image.fileId, croppingId: image.croppingId ?? null, filePath: image.filePath ?? null }]
		: [];
	const { data: damImages } = useImageLookup(images);
	const damImage = damImages?.images?.[0] ?? fallBackImage;

	const { className: builderClassNames, key, ...restOfAttributes } = attributes || {};

	const renderChildren = (): React.ReactNode => {
		if (fitContent) {
			return children;
		}
		return <div className={styles.childrenContainer}>{children}</div>;
	};

	const ImageComponent = damImage && (
		<>
			<Image
				className={styles.image}
				desktopSrc={damImage.size1800.url}
				desktopSrc2x={damImage.size1800.url}
				mobileSrc={damImage.size1800.url}
				mobileSrc2x={damImage.size1800.url}
				altText={altText || damImage.altText || ''}
				data-aspect-ratio={aspectRatio === 0 ? '0' : undefined}
			/>
			{!fitContent && aspectRatio !== undefined && aspectRatio > 0 && <div className={styles.aspectRatio}></div>}
		</>
	);
	const modal = useModal();

	return (
		<div
			className={classNames(
				styles.wrapper,
				{ [styles.fitContent]: fitContent },
				{ [styles.hasAspectRatio]: aspectRatio || fitContent },
				builderClassNames,
			)}
			key={key}
			{...restOfAttributes}
			style={
				{
					'--imageFit': imageFit ? imageFit : 'cover',
					'--imagePosX': imagePosition?.x !== undefined ? `${imagePosition.x}%` : 'center',
					'--imagePosY': imagePosition?.y !== undefined ? `${imagePosition.y}%` : 'center',
					'--aspectRatio': aspectRatio ? aspectRatio : undefined,
				} as React.CSSProperties
			}
		>
			{addZoom ? (
				<>
					<Button onClick={modal.showModal} hasNoStyles>
						{ImageComponent}
					</Button>
					<Modal className={styles.modal} {...modal} hideCloseButton>
						<div className={styles.modalHeader}>
							<Button
								hasOnlyIcon
								size="xs"
								variant="outline"
								className={styles.closeButton}
								onClick={modal.close}
							>
								<Icon size="sm" name={'close'} />
							</Button>
						</div>
						<ZoomableWrapper className={styles.zoomable}>{ImageComponent}</ZoomableWrapper>
					</Modal>
				</>
			) : (
				ImageComponent
			)}
			{children && renderChildren()}
		</div>
	);
};
