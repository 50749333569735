import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { SortOption } from 'components/fragments/SortDropdown/SortSection';
import { Badge } from 'components/shared/Badge';
import { Button } from 'components/shared/Button';
import { Drawer, DrawerHeader, DrawerBody, DrawerFooter } from 'components/shared/Drawer';
import { Icon } from 'components/shared/Icon';
import { SortDirection } from 'generated/data-contracts';
import { useFilterContext } from '../context/FilterContext';
import styles from './FilterDrawer.module.scss';
import { FilterDrawerItem } from './FilterDrawerItem';
import { FilterDrawerSortSection } from './FilterDrawerSortSection';

export interface FilterDrawerProps<T extends string> {
	sort?: {
		options: Map<T, SortOption<T>>;
		onChangeSort?: (sortOption: T, sortDirection: SortDirection) => void;
		sortDirection?: SortDirection;
		sortBy?: T;
	};
	excludeFilters?: string[];
	className?: string;
	numberOfSelectedFilters?: number;
}

export const FilterDrawer = <T extends string>({
	sort,
	className,
	excludeFilters,
	numberOfSelectedFilters,
}: FilterDrawerProps<T>) => {
	const { data: translations } = useTranslationQuery();
	const [isOpen, setIsOpen] = React.useState(false);
	const { filters, resetFilters, styling } = useFilterContext();
	const buttonLabel = sort
		? translations?.productList.filters.filtersAndSort
		: translations?.productList.filters.filtersTitle;
	const openDrawer = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);
	const onReset = () => {
		resetFilters();
	};
	const onSubmit = () => {
		setIsOpen(false);
	};
	return (
		<>
			<Button
				onClick={openDrawer}
				className={className}
				size={styling.button.size}
				variant={styling.button.variant}
			>
				<Icon name="filters" size="sm" />
				{buttonLabel}
				<Badge value={numberOfSelectedFilters} />
			</Button>
			{isOpen && (
				<Drawer onClose={handleClose} direction="Right">
					<DrawerHeader
						handleClose={handleClose}
						title={translations?.productList?.filters?.allFilters}
						titleSize="md"
						size="sm"
					/>
					<DrawerBody className={styles.mainContent}>
						{sort && (
							<FilterDrawerSortSection
								options={sort.options}
								sortBy={sort.sortBy}
								sortDirection={sort.sortDirection}
								onChangeSort={sort.onChangeSort}
							/>
						)}
						{filters.map((r) => {
							if (excludeFilters && excludeFilters?.includes(r.id)) return null;
							return <FilterDrawerItem filter={r} key={`${r.type}-${r.id}`} />;
						})}
					</DrawerBody>
					<DrawerFooter className={styles.footer}>
						<Button variant="outline" onClick={onReset}>
							{translations?.shared.reset}
						</Button>
						<Button variant="dark" onClick={onSubmit}>
							{translations?.shared.apply}
						</Button>
					</DrawerFooter>
				</Drawer>
			)}
		</>
	);
};
