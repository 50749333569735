import { Builder } from '@builder.io/react';
// Import all custom Builder.io components,
// so that they are bundled and accessible
import './components/features/Cms/blocks/Accordion/Accordion.builder';
import './components/features/Cms/blocks/Button/Button.builder';
import './components/features/Cms/blocks/Carousel/Carousel.builder';
import './components/features/Cms/blocks/DAMImage/DAMImage.builder';
import './components/features/Cms/blocks/DAMVideo/DAMVideo.builder';
import './components/features/Cms/blocks/Icon/Icon.builder';
import './components/features/Cms/blocks/Modal/Modal.builder';
import './components/features/Cms/blocks/PLP/PLP.builder';
import './components/features/Cms/blocks/ProductCard/ProductCard.builder';
import './components/features/Cms/blocks/ProductSlider/ProductSlider.builder';
import './components/features/Cms/blocks/Section/Section.builder';
import './components/features/Cms/blocks/Tabs/Tabs.builder';
import './components/features/Cms/blocks/Text/Text.builder';
import './components/features/Cms/blocks/Usp/Usp.builder';
import './components/features/Cms/blocks/VerticalSpacer/VerticalSpacer.builder';

Builder.register('editor.settings', { customInsertMenu: true });

Builder.register('insertMenu', {
	name: 'Content',
	priority: 1,
	items: [
		{ name: 'Core:Button' },
		{ name: 'Icon' },
		{ name: 'Image' },
		{ name: 'DamImage' },
		{ name: 'DamVideo' },
		{ name: 'Text' },
		{ name: 'Video' },
	],
});

Builder.register('insertMenu', {
	name: 'Layout',
	priority: 2,
	items: [
		{ name: 'Accordion' },
		{ name: 'Box' },
		{ name: 'Carousel' },
		{ name: 'Columns' },
		{ name: 'Core:Section' },
		{ name: 'Tabs' },
		{ name: 'VerticalSpacer' },
	],
});

Builder.register('insertMenu', {
	name: 'E-commerce',
	priority: 3,
	items: [
		{ name: 'BuyButton' },
		{ name: 'ProductCard' },
		{ name: 'ProductSlider' },
		{ name: 'Usp' },
		{ name: 'PLP' },
		{ name: 'NewPLP' },
	],
});

Builder.register('insertMenu', {
	name: 'Advanced',
	priority: 4,
	items: [{ name: 'Embed' }, { name: 'Dialog' }, { name: 'Symbol' }],
});
