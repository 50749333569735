import { isEmpty } from 'lodash';
import { SplashResponse, SplashType } from 'generated/data-contracts';

export type ProductSplashes = {
	[SplashType.DeliveryMonth]?: SplashResponse;
	[SplashType.Discount]?: SplashResponse;
	[SplashType.FashionForward]?: SplashResponse;
	[SplashType.NeverOutOfStock]?: SplashResponse;
	[SplashType.NeverOutOfStockType]?: SplashResponse;
	[SplashType.Cmi]?: SplashResponse;
};

export const useSanitizeSplashes = (splashes: SplashResponse[]): ProductSplashes => {
	if (isEmpty(splashes)) {
		return {};
	}

	const sanitizedSplashes = splashes.reduce<Partial<Record<SplashType, SplashResponse>>>((acc, cur) => {
		if (!cur.type) return acc;
		return {
			...acc,
			[cur.type]: cur ?? undefined,
		};
	}, {});

	return sanitizedSplashes;
};
