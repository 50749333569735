import React from 'react';
import { useSuspenseInfiniteQuery, UseSuspenseInfiniteQueryResult } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { Product as ProductApi } from 'generated/Product';
import { ProductListResponse, ProductSearchRequest, SearchRequest } from 'generated/data-contracts';
import { isScannerApp } from 'helpers/app';
import { getInfiniteQueryNextPage } from 'helpers/getInfiniteQueryNextPage';
import { useAppStore } from 'store/appStore';

export type ProductSearchQuery = Omit<SearchRequest, 'segmentationId'>;
export const useProductSearchQuery = (
	query: ProductSearchQuery,
): UseSuspenseInfiniteQueryResult<ProductListResponse> => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const productListApi = new ProductApi({
		baseApiParams: { headers: setHeaders() },
	});
	const searchQuery: SearchRequest = {
		segmentationId: segmentationId || 1,
		...query,
		page: undefined,
	};
	const newQueryKey = queryKeys.product.list(segmentationId, searchQuery).queryKey;
	const lastQueryKey = React.useRef(newQueryKey);
	const hasKeyChanged = !isEqual(newQueryKey, lastQueryKey.current);
	lastQueryKey.current = newQueryKey;
	return useSuspenseInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: newQueryKey,
		queryFn: async ({ pageParam }) => {
			const result = await productListApi.productSearchCreate({ ...searchQuery, page: pageParam });
			if (!result) throw new Error('Products api returned null');
			return result.data;
		},
		select: (data): ProductListResponse => {
			const firstPage = data.pages.sort((r) => r.pagingInformation.currentPage).at(0);
			if (!firstPage) throw new Error('Products api returned null');
			return {
				...firstPage,
				products: data.pages.flatMap((r) => r.products),
			};
		},
		getNextPageParam: (lastPage) => {
			return getInfiniteQueryNextPage(lastPage.pagingInformation);
		},
		getPreviousPageParam: (page) => {
			const { currentPage } = page.pagingInformation;
			return currentPage > 1 ? currentPage - 1 : undefined;
		},
		retry: false,
		refetchOnWindowFocus: isScannerApp ? 'always' : true,
		initialPageParam: hasKeyChanged ? 1 : query.page,
	});
};

const defaultProductBundlePageSize = 60;
export const useCmsProductSearchQuery = (
	query: ProductSearchRequest,
): UseSuspenseInfiniteQueryResult<ProductListResponse> => {
	const segmentationId = useAppStore((state) => state.segmentationId);
	const searchQuery: ProductSearchRequest = {
		...query,
		skip: 0,
	};
	const newQueryKey = queryKeys.product.bundleQuery2(segmentationId, searchQuery).queryKey;
	const lastQueryKey = React.useRef(newQueryKey);
	const hasKeyChanged = !isEqual(newQueryKey, lastQueryKey.current);
	lastQueryKey.current = newQueryKey;
	return useSuspenseInfiniteQuery({
		// currentPage should not be part of the key because react-query controls it internally
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: newQueryKey,
		queryFn: async ({ pageParam }) => {
			const productListApi = new ProductApi({
				baseApiParams: { headers: setHeaders() },
			});

			const skipAmount = (pageParam - 1) * (query.take ?? defaultProductBundlePageSize);
			const result = await productListApi.productSearchqueryCreate({ ...searchQuery, skip: skipAmount });
			if (!result) throw new Error('Products api returned null');
			return result.data;
		},
		select: (data): ProductListResponse => {
			const firstPage = data.pages.at(0);
			if (!firstPage) throw new Error('Products api returned null');
			return {
				...firstPage,
				products: data.pages.flatMap((r) => r.products),
			};
		},
		getNextPageParam: (lastPage) => {
			return getInfiniteQueryNextPage(lastPage.pagingInformation);
		},
		getPreviousPageParam: (page) => {
			const { currentPage } = page.pagingInformation;
			return currentPage > 1 ? currentPage - 1 : undefined;
		},
		retry: false,
		refetchOnWindowFocus: isScannerApp ? 'always' : true,
		initialPageParam: hasKeyChanged ? 1 : query.skip > 0 ? query.skip / query.take : 1,
	});
};
