import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Chip } from 'components/shared/Chip';
import { useFilterContext } from '../context/FilterContext';
import { FilterTypesEnum } from '../types/FilterTypes';
import styles from './ActiveFilterList.module.scss';

type PropsExtension =
	| {
			includeOnly?: string[];
	  }
	| {
			exclude?: string[];
	  };

export type ActiveFilterListProps = {
	className?: string;
} & PropsExtension;

export const ActiveFilterList: React.FunctionComponent<ActiveFilterListProps> = ({ className, ...props }) => {
	const {
		selectedFilters,
		checkboxActions,
		resetFilters,
		styling: {
			button: { variant },
		},
	} = useFilterContext();
	const { data: translations } = useTranslationQuery();

	const selectedFiltersList = React.useMemo(() => {
		return [...selectedFilters[FilterTypesEnum.Checkbox].entries()].flatMap(([filterId, val]) => {
			if ('includeOnly' in props) {
				if (!props.includeOnly?.includes(filterId)) return [];
			}
			if ('exclude' in props) {
				if (props.exclude?.includes(filterId)) return [];
			}
			return [...val.entries()].map(([itemId, value]) => ({ filterId, itemId, value }));
		});
	}, [props, selectedFilters]);

	if (!selectedFiltersList.length) return null;
	return (
		<section className={classNames(styles.wrapper, className)}>
			{selectedFiltersList?.map(({ filterId, itemId, value }) => {
				return (
					<Chip
						className={classNames({ [styles.disabledChip]: false })}
						onCancel={(): void => checkboxActions.onRemoveCheckboxItem(filterId, itemId)}
						key={filterId + itemId}
						buttonVariant={variant}
					>
						{value.label}
					</Chip>
				);
			})}
			{!!selectedFiltersList?.length && (
				<Button className={styles.resetButton} variant={'linkButton'} onClick={() => resetFilters(props)}>
					{translations?.productList.filters.resetFilters}
				</Button>
			)}
		</section>
	);
};
