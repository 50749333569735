import React, { PropsWithChildren, useRef } from 'react';
import classNames from 'classnames';
import styles from './ZoomableImage.module.scss';
import { useZoomable } from './useZoomable';

interface ZoomableProps {
	className?: string;
	imageClassName?: string;
}

export const ZoomableWrapper: React.FunctionComponent<PropsWithChildren<ZoomableProps>> = ({
	children,
	className,
	imageClassName,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);

	useZoomable({ ref, wrapperRef });

	return (
		<>
			<div
				className={classNames(className, styles.imageWrapper)}
				ref={wrapperRef}
				style={{
					overflow: 'clip',
				}}
			>
				<div className={classNames(imageClassName, styles.image)} ref={ref}>
					{children}
				</div>
			</div>
		</>
	);
};
