import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button, Icon } from 'components/shared';
import { SortDirection } from 'generated/data-contracts';
import styles from './SortDropdown.module.scss';

export interface SortOption<T extends string> {
	value: T;
	label: string;
	only?: SortDirection;
}

export interface SortSectionProps<T extends string> {
	headerClassName?: string;
	optionsClassName?: string;
	optionClassName?: string;
	sortBy?: T;
	sortDirection: SortDirection;
	setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
	setSortBy: React.Dispatch<React.SetStateAction<T>>;

	options: Map<T, SortOption<T>>;
}

export const SortSection = <T extends string>({
	sortDirection,
	headerClassName,
	optionsClassName,
	optionClassName,
	sortBy,
	setSortDirection,
	setSortBy,
	options,
}: SortSectionProps<T>) => {
	const { data: translations } = useTranslationQuery();
	if (!translations) return;

	return (
		<>
			<header className={classNames(headerClassName, styles.header)}>
				<h4 className={styles.title}>{translations.shared.sorting.sortBy}</h4>
				<div className={styles.sortDirections}>
					<Button
						variant={sortDirection === SortDirection.Ascending ? 'dark' : 'outline'}
						onClick={() => setSortDirection(SortDirection.Ascending)}
						size="xs"
						disabled={
							sortBy && options.get(sortBy)?.only && options.get(sortBy)?.only !== SortDirection.Ascending
						}
					>
						<Icon name="sortAscending" size="sm" />
						{translations.shared.sorting.ascending}
					</Button>
					<Button
						variant={sortDirection === SortDirection.Descending ? 'dark' : 'outline'}
						onClick={() => setSortDirection(SortDirection.Descending)}
						size="xs"
						disabled={
							sortBy &&
							options.get(sortBy)?.only &&
							options.get(sortBy)?.only !== SortDirection.Descending
						}
					>
						<Icon name="sortDescending" size="sm" />
						{translations.shared.sorting.descending}
					</Button>
				</div>
			</header>
			<div className={classNames(optionsClassName, styles.options)}>
				{[...options.values()].map(({ label, value, only }) => (
					<Button
						key={`basket-dropdown-${label}-${value}`}
						variant="ghost"
						className={classNames(optionClassName, styles.option)}
						onClick={() => {
							setSortBy(value);
							if (only) {
								setSortDirection(only);
							}
						}}
					>
						{label}
						{sortBy === value && <Icon name="checkMark" size="sm" />}
					</Button>
				))}
			</div>
		</>
	);
};
