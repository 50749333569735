import { Input } from '@builder.io/sdk';
import { ProductSort, SortDirection } from 'generated/data-contracts';

enum LayoutOptionKeys {
	showFilters = 'showFilters',
	showCategories = 'showCategories',
	numberOfProducts = 'numberOfProducts',
	allowPagination = 'allowPagination',
	defaultSorting = 'defaultSorting',
	sortingDirection = 'sortingDirection',
}

export interface CmsPlpLayoutOptions {
	showUpcomingProducts: boolean;
	showExpiredProducts: boolean;
	showFilters: boolean;
	allowPagination: boolean;
	showCategories: boolean;
	numberOfProducts?: number;
	defaultSorting?: ProductSort;
	sortingDirection?: SortDirection;
}

export const LAYOUT_OPTIONS: Input = {
	name: 'layout',
	friendlyName: 'Layout options',
	type: 'object',

	defaultValue: {
		allowPagination: true,
		showFilters: true,
		showCategories: true,
		numberOfProducts: 60,
	},
	subFields: [
		{
			name: LayoutOptionKeys.showFilters,
			type: 'boolean',
			friendlyName: 'Show filters',
			helperText: 'Toggle to display or hide the filters',
		},
		{
			name: LayoutOptionKeys.showCategories,
			type: 'boolean',
			friendlyName: 'Show categories',
			helperText: 'Toggle to display or hide the category sidebar',
		},
		{
			name: LayoutOptionKeys.numberOfProducts,
			type: 'number',
			required: true,
		},
		{
			name: LayoutOptionKeys.allowPagination,
			type: 'boolean',
			friendlyName: 'Allow pagination',
			helperText:
				'Toggle to allow for pagination. This can be helpful if you only want to show a specific amount of products.',
		},
		{
			name: LayoutOptionKeys.defaultSorting,
			type: 'string',
			enum: Object.values(ProductSort),
			required: false,
		},
		{
			name: LayoutOptionKeys.sortingDirection,
			type: 'string',
			enum: Object.values(SortDirection),
			required: false,
			showIf: (options): boolean => {
				return options.get('defaultSorting');
			},
		},
	],
};
