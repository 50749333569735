import { Input } from '@builder.io/sdk';

export interface CmsPlpFilterOptions {
	searchPhrase?: string;
	categoryIds: string[] | string;
	subcategoryIds: string[];
	collectionIds: string[];
	productLineIds: string[];
	deliveryMonths: string[];
	productCatalogues: string[];
	productSubcatalogues: string[];
	productAssortments: string[];
	productMainColours: string[];
	productSizes: string[];
	productFits: string[];
	productNoos: string[];
	productMyNoos: string[];
	productGender: string[];
	productFashions: string[];
	productSleeves: string[];
	productNecks: string[];
	productHasLicense: string[];
	productInStock: string[];
	productHasDiscountValues: string[];
}

export const FILTER_OPTIONS: Input = {
	name: 'filters',
	friendlyName: 'Filter options',
	type: 'object',
	defaultValue: {},
	subFields: [
		{
			name: 'searchPhrase',
			type: 'string',
			required: false,
		},
		{
			name: 'categoryIds',
			type: 'Bestseller Product Search Categories',
			friendlyName: 'Categories',
			required: false,
		},
		{
			name: 'subcategoryIds',
			type: 'Bestseller Product Search Subcategories',
			friendlyName: 'Subcategories',
			required: false,
		},
		{
			name: 'collectionIds',
			type: 'Bestseller Product Search Collections',
			friendlyName: 'Collections',
			required: false,
		},
		{
			name: 'productLineIds',
			type: 'Bestseller Product Search Product Lines',
			friendlyName: 'Product Lines',
			required: false,
		},
		{
			name: 'deliveryMonths',
			type: 'Bestseller Product Search Delivery Months',
			required: false,
		},
		{
			name: 'productCatalogues',
			friendlyName: 'Catalogues',
			type: 'Bestseller Product Search Catalogues',
			required: false,
		},
		{
			name: 'productSubcatalogues',
			friendlyName: 'Subcatalogues',
			type: 'Bestseller Product Search Subcatalogues',
			required: false,
		},
		{
			name: 'productAssortments',
			friendlyName: 'Assortment Type',
			type: 'Bestseller Product Search Product Assortments',
			required: false,
		},
		{
			name: 'productMainColours',
			type: 'Bestseller Product Search Product Main Colours',
			friendlyName: 'Product colours',
			required: false,
		},
		{
			name: 'productSizes',
			type: 'Bestseller Product Search Product Sizes',
			required: false,
		},
		{
			name: 'productFits',
			type: 'Bestseller Product Search Product fits',
			required: false,
		},
		{
			name: 'productNoos',
			type: 'Bestseller Product Search Product Noos',
			required: false,
		},
		{
			name: 'productMyNoos',
			type: 'boolean',
			required: false,
		},
		{
			name: 'productGender',
			type: 'Bestseller Product Search Product Gender',
			friendlyName: 'Gender',
			required: false,
		},
		{
			name: 'productFashions',
			type: 'Bestseller Product Search Product Fashions',
			friendlyName: 'Fashion Forward',
			required: false,
		},
		{
			name: 'productSleeves',
			type: 'Bestseller Product Search Product Sleeves',
			friendlyName: 'Sleeves',
			required: false,
		},
		{
			name: 'productNecks',
			type: 'Bestseller Product Search Product Necks',
			friendlyName: 'Necks',
			required: false,
		},
		{
			name: 'productHasLicense',
			type: 'Bestseller Product Search Product Has License',
			friendlyName: 'Has License',
			required: false,
		},
		{
			name: 'productInStock',
			type: 'Bestseller Product Search Product InStock Availability level',
			friendlyName: 'In Stock',
			required: false,
		},
		{
			name: 'productHasDiscountValues',
			type: 'Bestseller Product Search Product Has Discount',
			friendlyName: 'Has Discount',
			required: false,
		},
	],
};
