import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { SortOption, SortSection } from 'components/fragments/SortDropdown/SortSection';
import { Button } from 'components/shared/Button';
import { DrawerLevel, DrawerHeader, DrawerBody, DrawerFooter } from 'components/shared/Drawer';
import { Icon } from 'components/shared/Icon';
import { SortDirection } from 'generated/data-contracts';
import styles from './FilterDrawerSortSection.module.scss';

export interface FilterDrawerSortSectionProps<T extends string> {
	options: Map<T, SortOption<T>>;
	sortBy?: T;
	sortDirection?: SortDirection;
	onChangeSort?: (sortOption: T, sortDirection: SortDirection) => void;
}

export const FilterDrawerSortSection = <T extends string>({
	options,
	onChangeSort,
	sortDirection: controlledDirection = SortDirection.Descending,
	sortBy: controlledSortBy = options.keys().next().value,
}: FilterDrawerSortSectionProps<T>) => {
	const { data: translations } = useTranslationQuery();
	const [isActive, setIsActive] = React.useState(false);
	const [sortDirection, setSortDirection] = React.useState<SortDirection>(controlledDirection);
	const [sortBy, setSortBy] = React.useState<T>(controlledSortBy);

	const onClick = () => setIsActive(true);
	const onCancel = () => {
		setSortDirection(controlledDirection);
		setSortBy(controlledSortBy);
		setIsActive(false);
	};
	const onSubmit = () => {
		if (sortBy) onChangeSort?.(sortBy, sortDirection);
		setIsActive(false);
	};
	return (
		<>
			<Button hasNoStyles className={styles.button} onClick={onClick}>
				<div className={styles.mobileSortingInfo}>
					<span className={styles.label}>{translations?.shared.sorting.sortBy}</span>
					<span>{options.get(sortBy)?.label}</span>
				</div>
				<Icon name="chevronDown" size="md" rotate={270} />
			</Button>
			{isActive && (
				<DrawerLevel isActive={isActive}>
					<DrawerHeader handleBack={onCancel} showBackButton handleClose={onCancel}>
						<span className={styles.title}>{translations?.shared.sorting.sortBy}</span>
					</DrawerHeader>
					<DrawerBody className={styles.drawerBody}>
						<SortSection
							sortDirection={sortDirection}
							setSortDirection={setSortDirection}
							sortBy={sortBy}
							setSortBy={setSortBy}
							options={options}
						/>
					</DrawerBody>
					<DrawerFooter className={styles.footer}>
						<Button variant="outline" onClick={onCancel}>
							{translations?.shared.cancel}
						</Button>
						<Button variant="dark" onClick={onSubmit}>
							{translations?.shared.apply}
						</Button>
					</DrawerFooter>
				</DrawerLevel>
			)}
		</>
	);
};
