import React, { ReactElement, createContext, useContext, useRef } from 'react';
import { useViewportSize } from 'helpers/useViewportSize';

type SlideState = {
	numberOfSlides: number;
	setNumberOfSlides: (numberOfSlides: SlideState['numberOfSlides']) => void;

	activeSlide: number;
	setActiveSlide: (index: number, disableIntersection?: boolean) => void;

	intersectionEnabled: boolean;
	setIntersectionEnabled: (enabled: boolean) => void;

	nextSlide: () => void;
	prevSlide: () => void;
};

type Config = {
	showNavigation: boolean;
	showBadge: boolean;
};

type CarouselState = {
	carouselRef: React.RefObject<HTMLUListElement>;
	slides: SlideState;
	config: Config;
};

export const CarouselContext = createContext<CarouselState | null>(null);

export interface CarouselProviderProps extends React.PropsWithChildren {
	showNavigation?: boolean;
	showBadge?: boolean;
}

export const CarouselProvider = ({ children, showNavigation, showBadge }: CarouselProviderProps): ReactElement => {
	const listRef = useRef<HTMLUListElement>(null);
	const [numberOfSlides, setNumberOfSlides] = React.useState(0);
	const [activeSlide, setActiveSlide] = React.useState(0);
	const [intersectionEnabled, setIntersectionEnabled] = React.useState<boolean>(false);

	const nextSlide = () => {
		setIntersectionEnabled(false);
		setActiveSlide((prev) => prev + 1);
	};
	const prevSlide = () => {
		setIntersectionEnabled(false);
		setActiveSlide((prev) => prev - 1);
	};
	const changeActiveSlide = (index, disableIntersection = true) => {
		setIntersectionEnabled(!disableIntersection);
		setActiveSlide(index);
	};

	const { isMobile } = useViewportSize();

	const store = {
		carouselRef: listRef,
		slides: {
			numberOfSlides,
			setNumberOfSlides,
			activeSlide,
			intersectionEnabled,
			setIntersectionEnabled,
			nextSlide,
			prevSlide,
			setActiveSlide: changeActiveSlide,
		},
		config: {
			showNavigation: showNavigation ?? !isMobile,
			showBadge: showBadge ?? isMobile,
		},
	};
	return <CarouselContext.Provider value={store}>{children}</CarouselContext.Provider>;
};

export const useCarouselStore = (): CarouselState => {
	const store = useContext(CarouselContext);
	if (!store) {
		throw new Error('Missing CarouselProvider');
	}
	return store;
};
