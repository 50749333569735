import React, { useEffect } from 'react';
import classNames from 'classnames';
import { TabItem, useNewTabsState } from '../NewTabsContext';
import styles from './NewTabPanel.module.scss';

interface NewTabPanelProps {
	tabId: TabItem['id'];
	children: ((props: { isActive: boolean }) => React.ReactNode) | React.ReactNode;
}

export const NewTabPanel: React.FunctionComponent<NewTabPanelProps> = ({ children, tabId }) => {
	const { animationTime: transitionTime, tabList, selectedTab } = useNewTabsState();
	const tabName = tabList.find((t) => t.id === tabId)?.label;
	const hidden = selectedTab !== tabId;
	const hasBeenRendered = React.useRef(!hidden);
	const [isFullyHidden, setIsFullyHidden] = React.useState(hidden);
	useEffect(() => {
		if (hidden !== isFullyHidden) {
			setTimeout(() => setIsFullyHidden(hidden), transitionTime / 2);
		}
	}, [hidden, isFullyHidden, transitionTime]);

	useEffect(() => {
		// If the tab is ever shown, keep it in the DOM to avoid re-rendering (and thus reloading content)
		if (!hidden) {
			hasBeenRendered.current = true;
		}
	}, [hidden]);

	if (hidden && isFullyHidden && !hasBeenRendered.current) {
		return null;
	}
	return (
		<div
			role="tabpanel"
			aria-labelledby={tabName}
			hidden={isFullyHidden}
			style={{ '--transition-time': `${transitionTime / 2}ms` } as React.CSSProperties}
			className={classNames(styles.tabPanel, {
				[styles.fadeOut]: hidden !== isFullyHidden,
			})}
		>
			{typeof children === 'function' ? children({ isActive: !hidden }) : children}
		</div>
	);
};
