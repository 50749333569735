import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'library/routing';
import { cloneDeep } from 'lodash';
import { ButtonProps } from 'components/shared/Button';
import { FilterValueResponse } from 'generated/data-contracts';
import { getFiltersFromUrl, setFiltersInUrl } from '../helpers/getFiltersFromUrl';
import { DEFAULT_FILTER_PARAM } from 'helpers/useFilters';
import { FilterTypes, FilterState, DEFAULT_FILTER_STATE } from '../types/FilterState';
import { FilterTypesEnum } from '../types/FilterTypes';
import { useCheckboxFilterState, UseCheckboxFilterStateReturnType } from './useCheckboxFilterState';
import { useRangeFilterState, UseRangeFilterStateReturnType } from './useRangeFilterState';
import { useSingleFilterState, UseSingleFilterStateReturnType } from './useSingleFilterState';

type FilterStyling = {
	button: Pick<ButtonProps, 'variant' | 'size' | 'className'>;
};

type ResetFilterParam =
	| {
			includeOnly?: string[];
	  }
	| {
			exclude?: string[];
	  };

type UseFilterContextProps = (
	| {
			storeInUrl?: never | false;
			urlParam?: never;
	  }
	| {
			storeInUrl: true;
			urlParam?: string;
	  }
) & {
	styling?: FilterStyling;
};

interface UseFilterContextReturnType {
	filters: FilterTypes[];
	selectedFilters: FilterState;
	uncommittedFilters: FilterState;
	setAvailableFilters: React.Dispatch<React.SetStateAction<FilterTypes[]>>;
	styling: FilterStyling;
	closeWithoutSaving: () => void;
	commitFilters: () => void;
	resetFilters: (props?: ResetFilterParam) => void;
	checkboxActions: UseCheckboxFilterStateReturnType;
	rangeFilterActions: UseRangeFilterStateReturnType;
	singleFilterActions: UseSingleFilterStateReturnType;
	getActiveCount: (filterType: FilterTypesEnum, filterId: string) => number;
}

const DEFAULT_STYLING: FilterStyling = {
	button: {
		variant: 'outline',
		size: 'sm',
	},
};

// Use set instead of object
const useFilterContextState = (props: UseFilterContextProps = {}): UseFilterContextReturnType => {
	const { storeInUrl = true, urlParam = DEFAULT_FILTER_PARAM, styling = DEFAULT_STYLING } = props;
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [touched, setTouched] = useState(false);
	const [filters, setFilters] = React.useState<FilterTypes[]>([]);
	const [selectedFilters, setSelectedFilters] = React.useState<FilterState>(() => {
		if (!storeInUrl) return cloneDeep(DEFAULT_FILTER_STATE);

		let filterList: FilterState = cloneDeep(DEFAULT_FILTER_STATE);
		try {
			filterList = getFiltersFromUrl(searchParams, urlParam);
		} catch {
			// Filters object is borken. Delete it
			setSearchParams(
				(p) => {
					p.delete(urlParam);
					return p;
				},
				{
					replace: true,
					state: location.state,
				},
			);
		}
		return filterList;
	});
	const [uncommittedFilters, setUncommittedFilters] = React.useState<FilterState>(selectedFilters);
	const checkboxActions = useCheckboxFilterState({
		selectedFilters,
		setSelectedFilters,
		setUncommittedFilters,
		uncommittedFilters,
		filters: filters.filter((r) => r.type === FilterTypesEnum.Checkbox),
	});
	const rangeFilterActions = useRangeFilterState({
		selectedFilters,
		setSelectedFilters,
		setUncommittedFilters,
		uncommittedFilters,
		filters: filters.filter((r) => r.type === FilterTypesEnum.Range),
	});
	const singleFilterActions = useSingleFilterState({
		selectedFilters,
		setSelectedFilters,
		setUncommittedFilters,
		uncommittedFilters,
		filters: filters.filter((r) => r.type === FilterTypesEnum.Single),
	});

	const resetFilters = React.useCallback(
		(props: ResetFilterParam = {}) => {
			setSelectedFilters((prev) => {
				const newState = cloneDeep(DEFAULT_FILTER_STATE);
				if ('exclude' in props && props.exclude) {
					props.exclude?.forEach((excludedFilter) => {
						const foundFilter = filters.find((r) => r.id === excludedFilter);
						if (!foundFilter) return;
						switch (foundFilter.type) {
							case FilterTypesEnum.Checkbox: {
								const val = prev[FilterTypesEnum.Checkbox].get(excludedFilter);
								if (!val) return;
								newState[FilterTypesEnum.Checkbox].set(excludedFilter, val);
								return;
							}
						}
					});
					return newState;
				}
				if ('includeOnly' in props) {
					props.includeOnly?.forEach((includedFilter) => {
						prev[FilterTypesEnum.Checkbox].delete(includedFilter);
					});
					return prev;
				}
				return cloneDeep(DEFAULT_FILTER_STATE);
			});
		},
		[filters],
	);

	React.useEffect(() => {
		setTouched(true);
		setUncommittedFilters(cloneDeep(selectedFilters));
	}, [selectedFilters]);

	React.useEffect(() => {
		if (storeInUrl && touched) {
			setSearchParams((prev) => setFiltersInUrl(selectedFilters, prev, urlParam), {
				replace: true,
				state: location.state,
			});
		}
	}, [location.state, selectedFilters, setSearchParams, storeInUrl, touched, urlParam]);

	const commitFilters = React.useCallback(() => {
		setSelectedFilters(cloneDeep(uncommittedFilters));
	}, [uncommittedFilters]);

	const closeWithoutSaving = React.useCallback(() => {
		setUncommittedFilters(cloneDeep(selectedFilters));
	}, [selectedFilters]);

	const getActiveCount = React.useCallback(
		(filterType: FilterTypesEnum, filterId: string): number => {
			switch (filterType) {
				case FilterTypesEnum.Checkbox: {
					const filter = selectedFilters[FilterTypesEnum.Checkbox].get(filterId);
					return filter?.size ?? 0;
				}
				case FilterTypesEnum.Date: {
					const filter = selectedFilters[FilterTypesEnum.Date].get(filterId);
					return !!filter?.min && !!filter?.max ? 1 : 0;
				}
				case FilterTypesEnum.Range: {
					const filter = selectedFilters[FilterTypesEnum.Range].get(filterId);
					return !!filter?.min && !!filter?.max ? 1 : 0;
				}
				default:
					return 0;
			}
		},
		[selectedFilters],
	);

	/** Adds labels from the API to the filters that need it */
	const addLabelsToFilters = React.useCallback(
		(newFilters: FilterTypes[]) => (prevVal: FilterState) => {
			newFilters.forEach((filter) => {
				if (filter.type === FilterTypesEnum.Checkbox) {
					const values = prevVal[FilterTypesEnum.Checkbox].get(filter.id);
					if (!values) return;
					const newValues = new Map<string, FilterValueResponse>();
					Array.from(values.keys()).forEach((key) => {
						const valueFromApi = filter.values.find((f) => f.value === key);
						if (!valueFromApi) {
							const oldVal = values.get(key);
							oldVal && newValues.set(key, oldVal);
							return;
						}
						newValues.set(key, {
							isSelected: true,
							label: valueFromApi.label,
							value: valueFromApi.value,
						});
					});
					prevVal[FilterTypesEnum.Checkbox].set(filter.id, newValues);
					return;
				}
				if (filter.type === FilterTypesEnum.Single) {
					const value = prevVal[FilterTypesEnum.Single].get(filter.id);
					if (!value) return;
					const valueFromApi = filter.values.find((f) => f.value === value.value);
					if (!valueFromApi) return;
					prevVal[FilterTypesEnum.Single].set(filter.id, valueFromApi);
					return;
				}
			});
			// We do not want to deepclone this, as the dependent effects should not be executed by this change.
			// It will only change the properties of already created fields, and thus we do not have to recalculate everything based on selectedFilters
			return prevVal;
		},
		[],
	);

	const setAvailableFilters: React.Dispatch<React.SetStateAction<FilterTypes[]>> = React.useCallback(
		(state: React.SetStateAction<FilterTypes[]>) => {
			setFilters((prev) => {
				let newState: FilterTypes[] = prev;
				if (typeof state === 'function') {
					newState = state(prev);
				} else {
					newState = state;
				}
				setSelectedFilters(addLabelsToFilters(newState));
				setUncommittedFilters(addLabelsToFilters(newState));

				return newState;
			});
		},
		[addLabelsToFilters],
	);

	return {
		filters,
		selectedFilters,
		uncommittedFilters,
		setAvailableFilters,
		styling,
		closeWithoutSaving,
		checkboxActions,
		rangeFilterActions,
		singleFilterActions,
		getActiveCount,
		commitFilters,
		resetFilters,
	};
};

const FilterContextContext = React.createContext<UseFilterContextReturnType | null>(null);

type FilterContextProviderProps = UseFilterContextProps & {
	children: ((props: UseFilterContextReturnType) => React.ReactNode) | React.ReactNode;
};
export const FilterContextProvider: React.FunctionComponent<FilterContextProviderProps> = ({
	children,
	...props
}: FilterContextProviderProps) => {
	const state = useFilterContextState(props);

	return (
		<FilterContextContext.Provider value={state}>
			{typeof children === 'function' ? children(state) : children}
		</FilterContextContext.Provider>
	);
};

export const useFilterContext = (): UseFilterContextReturnType => {
	const context = React.useContext(FilterContextContext);

	if (!context) {
		throw new Error('useFilterContext must be used within a FilterContextProvider');
	}

	return context;
};
