import React, { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { Badge } from 'components/shared/Badge';
import { ButtonProps } from 'components/shared/Button';
import { Dropdown } from 'components/shared/Dropdown';
import { FilterRequest, FilterType, FilterValueResponse } from 'generated/data-contracts';
import { FilterContent } from '../FilterContent';
import styles from './SearchableFilter.module.scss';

export interface SearchableFilterType {
	id: string;
	name: string;
	type: FilterType;
	values: FilterValueResponse[];
	search: {
		searchText: string;
		setSearchText: Dispatch<SetStateAction<string>>;
		clearSearchText: () => void;
		noResultsMsg?: string;
	};
	hasNextPage: boolean;
	loadMore: () => void;
	renderInPortal?: boolean;
}
export interface SearchableFilterProps extends SearchableFilterType {
	className?: string;
	disabled?: boolean;
	renderInPortal?: boolean;
	onSubmit?: (id: string) => void;
	activeCount?: number;
	toBeSet?: FilterRequest[];
	toBeRemoved?: FilterRequest[];
	setToBeSetFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	setToBeRemovedFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	buttonVariant: ButtonProps['variant'];
}

export const SearchableFilter: React.FunctionComponent<SearchableFilterProps> = ({
	className,
	setToBeRemovedFilters,
	setToBeSetFilters,
	toBeRemoved,
	hasNextPage,
	toBeSet,
	id,
	values,
	onSubmit,
	name,
	activeCount,
	type,
	search,
	loadMore,
	disabled,
	renderInPortal = false,
	buttonVariant,
}) => {
	const formRef = React.useRef<HTMLFormElement>(null);
	const [forceClosed, setForceClosed] = React.useState(false);
	const hasChanged =
		toBeSet?.some(
			(filter) =>
				filter.filter === id && values?.find((value) => value.value === filter.value)?.isSelected === false,
		) ||
		toBeRemoved?.some(
			(filter) => filter.filter === id && values?.find((value) => value.value === filter.value)?.isSelected,
		);

	React.useEffect(() => {
		setForceClosed(false);
	}, [toBeSet, toBeRemoved]);

	const handleSubmit = (e): void => {
		e.preventDefault();
		if (!onSubmit) return;
		setForceClosed(true);
		onSubmit(id);
		search.clearSearchText();
	};

	const renderButtonLabel = (): React.ReactElement => (
		<>
			<span>{name}</span>
			<Badge value={activeCount} />
		</>
	);

	return (
		<Dropdown
			className={classNames(styles.dropdownWrapper, className)}
			contentClassName={classNames(styles.dropdownContent, {
				[styles.dropDownContainer]: type !== FilterType.Number,
			})}
			buttonClassName={styles.dropdownButton}
			forceClosed={forceClosed}
			buttonVariant={buttonVariant}
			buttonSize={'sm'}
			disabled={disabled}
			isStatic
			unmountOnClose={renderInPortal}
			buttonLabel={renderButtonLabel()}
		>
			<form name={`filterForm-${id}`} className={styles.form} onSubmit={handleSubmit} ref={formRef}>
				<fieldset className={styles.formFieldSet}>
					<legend className={styles.legend}>{name}</legend>
					<FilterContent
						type={type}
						name={name}
						id={id}
						values={values}
						toBeSet={toBeSet}
						toBeRemoved={toBeRemoved}
						setToBeSetFilters={setToBeSetFilters}
						setToBeRemovedFilters={setToBeRemovedFilters}
						hasChanged={hasChanged}
						hasNextPage={hasNextPage}
						loadMore={loadMore}
						search={search}
					/>
				</fieldset>
			</form>
		</Dropdown>
	);
};
