import React, { FC } from 'react';
import classNames from 'classnames';
import { LayoutWrapper } from '../../components/LayoutWrapper';
import { PageWrapper } from '../../components/PageWrapper';
import { ProductList } from '../../components/ProductList';
import { skeletonProductCards } from 'components/features/ProductList/helpers';
import { LoadingBar } from 'components/shared';
import { PlpProductCard } from 'components/shared/ProductCard/PlpProductCard';
import { useViewportSize } from 'helpers/useViewportSize';
import styles from './PLPFetching.module.scss';

interface PLPFetchingProps {
	noPadding?: boolean;
	showFilters?: boolean;
	showCategories?: boolean;
}

export const PLPFetching: FC<PLPFetchingProps> = ({ noPadding = false, showCategories = true, showFilters = true }) => {
	const { isMobile } = useViewportSize();
	const PLPLayout = () => (
		<LayoutWrapper>
			{!isMobile && showCategories && (
				<section className={styles.categoryMenu}>
					<h1 className={styles.categoriesHeader}>
						<LoadingBar height="1.5rem" width="7.5rem" />
					</h1>
					<ul className={styles.categoryList}>
						<LoadingBar height="1.25rem" width="5rem" />
						<LoadingBar height="1.25rem" width="5.625rem" />
						<LoadingBar height="1.25rem" width="4.375rem" />
						<LoadingBar height="1.25rem" width="3.75rem" />
					</ul>
				</section>
			)}
			<ProductList.Wrapper>
				{isMobile
					? (showFilters || showCategories) && (
							<div className={styles.productListHeader}>
								<div
									className={classNames(styles.mobileFilters, {
										[styles.hasOnly1Item]: !showCategories || !showFilters,
									})}
								>
									{showFilters && <LoadingBar className={styles.filter} />}
									{showCategories && <LoadingBar className={styles.filter} />}
								</div>
								<div>
									<LoadingBar width="5rem" height="1.5rem" />
								</div>
							</div>
					  )
					: showFilters && (
							<div className={styles.productListHeader}>
								<div className={styles.filtersList}>
									<div className={styles.filters}>
										<LoadingBar width="7.5rem" height="2.5rem" className={styles.filter} />
										<LoadingBar width="7.5rem" height="2.5rem" className={styles.filter} />
										<LoadingBar width="7.5rem" height="2.5rem" className={styles.filter} />
										<LoadingBar width="7.5rem" height="2.5rem" className={styles.filter} />
									</div>
									<div>
										<LoadingBar width="5rem" height="2.5rem" className={styles.filter} />
									</div>
								</div>
								<div>
									<LoadingBar width="5rem" height="1.5rem" />
								</div>
							</div>
					  )}

				<div className={styles.container}>
					<section
						className={classNames(styles.productList, {})}
						style={{ '--js-number-of-columns': 'auto-fill' } as React.CSSProperties}
					>
						{skeletonProductCards(24).map((_, index) => (
							<PlpProductCard key={`skeleton${index}`} />
						))}
					</section>
				</div>
			</ProductList.Wrapper>
		</LayoutWrapper>
	);
	if (noPadding) {
		return <PLPLayout />;
	}
	return (
		<PageWrapper>
			<header className={styles.header}>
				<div className={styles.titleColumn}>
					<LoadingBar className={styles.category} />
				</div>
			</header>
			<PLPLayout />
		</PageWrapper>
	);
};
