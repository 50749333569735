import React, { PropsWithChildren } from 'react';
import { useTranslationQuery } from 'api/translations';
import { OrderHistorySearchGroupingTypeExtendedValue } from 'components/features/MyOrdersPage/hooks/useGroupingFilter';
import { useGetOrderDetailBreadCrumb } from 'components/features/OrderDetail/hooks/useGetOrderDetailBreadCrumb';
import {
	OrderHistoryViewportSize,
	useOrderHistoryViewportSize,
} from 'components/shared/hooks/useOrderHistoryViewportSize';
import {
	ColourResponse,
	OrderHistoryInvoiceResponse,
	OrderHistoryProductMasterResponse,
	OrderLineResponse,
	OrderOverviewResponse,
	ProductImageResponse,
	TrackAndTraceResponse,
} from 'generated/data-contracts';

type OrderLineProductInfo = {
	styleName?: string;
	styleNumber: string;
	bundleName?: string;
	familyNumber?: string;
	brandName?: string;
	mainColor?: ColourResponse;
	primaryImage?: ProductImageResponse;
	masters?: OrderHistoryProductMasterResponse[];
	productImages?: ProductImageResponse[];
	isFreeAssortment?: boolean;
	productUrl?: string;
};

export type OrderLineDetails = {
	brand?: string;
	account?: string;
	createdBy?: string;
	createdDate?: string;
	customerRef?: string;
	orderNumber?: string;
	orderStatus?: string;
	isDelivered: boolean;
	basketId?: string | number;
	url?: string;
	estimatedShippingDate?: string;
	warehouseShippingETA?: string;
	platform?: string;
	quantity?: string;
	bundleSize?: number;
	totalItems?: number;
	recommendedRetailPrice?: string;
	wholesalePrice?: string;
	wholesaleNetPrice?: string;
	markup?: string;
	discount?: string;
	value?: string;
	isFreeAssortment?: boolean;
	trackAndTraceCodes: TrackAndTraceResponse[];
	invoices: OrderHistoryInvoiceResponse[];
};

type UseOrderLineContextReturnType = {
	productInfo?: OrderLineProductInfo;
	orderLineDetails: OrderLineDetails;
	isSkeleton: boolean;
	size: OrderHistoryViewportSize;
	isOverview: boolean;
};

const useGetProductInfo = (orderLine?: Response): OrderLineProductInfo | undefined => {
	const { data: translations } = useTranslationQuery();
	if (!orderLine || !('product' in orderLine)) return undefined;
	const { product, bundleName, isFreeAssortment } = orderLine;

	if (!product) {
		return {
			isFreeAssortment: isFreeAssortment ?? undefined,
			bundleName: bundleName ?? '',
			styleName: translations?.myOrders.orderDetails.styleNotFound ?? '',
			styleNumber: translations?.myOrders.orderDetails.notAvailable ?? '',
		};
	}
	const masters = product.masters;
	const styleName = product.styleName ?? translations?.myOrders.orderDetails.styleNotFound ?? '';
	const styleNumber = product.styleNumber;
	let mainColor: ColourResponse | undefined = product.primaryColour;
	let primaryImage = product.primaryImage;
	const productImages = product.productImages;
	const firstMaster = masters[0];
	const brandName = product.brandName ?? undefined;
	if (firstMaster) {
		if (mainColor === undefined) mainColor = firstMaster.colour;
		if (primaryImage === undefined) primaryImage = firstMaster.image;
	}
	const productUrl = product.url ?? undefined;

	return {
		styleName,
		familyNumber: `F_${styleNumber}`,
		styleNumber,
		mainColor,
		primaryImage,
		masters,
		productImages,
		brandName,
		productUrl,
		bundleName: bundleName ?? '',
		isFreeAssortment: isFreeAssortment ?? undefined,
	};
};

type Response = OrderOverviewResponse | OrderLineResponse;

const useGetOrderLineDetails = (response: Response): OrderLineDetails => {
	const { data: translations } = useTranslationQuery();
	const NOT_AVAILABLE = translations?.myOrders.orderDetails.notAvailable;
	// These fields are on both types of OrderLines
	const { orderNumber, basketId, platform } = response;

	let orderStatus: OrderLineDetails['orderStatus'];
	let isDelivered: OrderLineDetails['isDelivered'] = false;
	let estimatedShippingDate: OrderLineDetails['estimatedShippingDate'];
	let warehouseShippingETA: OrderLineDetails['warehouseShippingETA'];
	let quantity: OrderLineDetails['quantity'];
	let bundleSize: OrderLineDetails['bundleSize'];
	let totalItems: OrderLineDetails['totalItems'];
	let wholesalePrice: OrderLineDetails['wholesalePrice'];
	let value: OrderLineDetails['value'];
	let isFreeAssortment: OrderLineDetails['isFreeAssortment'];
	let trackAndTraceCodes: OrderLineDetails['trackAndTraceCodes'] = [];
	let invoices: OrderLineDetails['invoices'] = [];
	let discount: OrderLineDetails['discount'];
	let markup: OrderLineDetails['markup'];
	let recommendedRetailPrice: OrderLineDetails['recommendedRetailPrice'];
	let wholesaleNetPrice: OrderLineDetails['wholesaleNetPrice'];
	let account: OrderLineDetails['account'];
	let brand: OrderLineDetails['brand'];
	let createdDate: OrderLineDetails['createdDate'];
	let createdBy: OrderLineDetails['createdBy'];
	let customerRef: OrderLineDetails['customerRef'];

	if ('wholeSalePrice' in response) {
		wholesalePrice = response.wholeSalePrice?.toFixed(2);
		markup = response.markupPrice?.toFixed(2);
		recommendedRetailPrice = response.rrp?.toFixed(2);
		wholesaleNetPrice = response.unitPrice?.toFixed(2);
		discount = response.discount;
		isDelivered = response.isDelivered;
		estimatedShippingDate = response.estimatedShippingDate ?? NOT_AVAILABLE;
		warehouseShippingETA = response.warehouseShippingETA ?? NOT_AVAILABLE;
		orderStatus = response.status ?? NOT_AVAILABLE;
		bundleSize = response.bundleSize;
		totalItems = response.orderLineQuantityPieces;
		isFreeAssortment = response.isFreeAssortment ?? undefined;
		trackAndTraceCodes = response.trackAndTrace;
		invoices = response.invoices;
		quantity = isFreeAssortment ? `${response.bundleQuantity}` : `${response.bundleQuantity} x ${bundleSize}`;
		value = wholesaleNetPrice;
	} else {
		account = response.accountName ?? NOT_AVAILABLE;
		createdBy = response.basketCreatorName ?? NOT_AVAILABLE;
		customerRef = response.clientReference ?? NOT_AVAILABLE;
		createdDate = response.createdDate ?? NOT_AVAILABLE;
		wholesaleNetPrice = response.totalOrderNetPrice ?? NOT_AVAILABLE;
		brand = response.brandName ?? NOT_AVAILABLE;
		quantity = response.totalOrderQuantity.toString();
		value = wholesaleNetPrice;
	}

	const route = useGetOrderDetailBreadCrumb(orderNumber);
	const url = route?.link;
	return {
		orderNumber,
		orderStatus: orderStatus ?? NOT_AVAILABLE,
		isDelivered,
		basketId,
		estimatedShippingDate: estimatedShippingDate ?? NOT_AVAILABLE,
		warehouseShippingETA: warehouseShippingETA ?? NOT_AVAILABLE,
		platform: platform ?? NOT_AVAILABLE,
		quantity,
		bundleSize,
		totalItems,
		wholesalePrice: wholesalePrice,
		value: value ?? undefined,
		isFreeAssortment: isFreeAssortment ?? undefined,
		trackAndTraceCodes,
		invoices,
		url,
		discount,
		markup,
		recommendedRetailPrice,
		wholesaleNetPrice,
		account,
		brand,
		createdDate,
		createdBy,
		customerRef,
	};
};

const useOrderLineContextState = ({
	isSkeleton,
	response,
	setSize,
}: {
	isSkeleton: boolean;
	response: Response;
	setSize?: OrderHistoryViewportSize;
}): UseOrderLineContextReturnType => {
	const orderLineDetails = useGetOrderLineDetails(response);
	const productInfo = useGetProductInfo(response);

	let isOrderOverview = false;
	if ('basketCreatorName' in response) {
		isOrderOverview = true;
	}
	const size = useOrderHistoryViewportSize(
		isOrderOverview ? OrderHistorySearchGroupingTypeExtendedValue.Overview : undefined,
	);
	return {
		productInfo,
		orderLineDetails,
		isSkeleton,
		size: setSize ?? size,
		isOverview: isOrderOverview,
	};
};

const OrderLineContext = React.createContext<UseOrderLineContextReturnType | null>(null);

export const OrderLineContextProvider: React.FunctionComponent<
	PropsWithChildren<{
		setSize?: OrderHistoryViewportSize;
		response: Response;
		isSkeleton?: boolean;
	}>
> = ({ children, response, setSize, isSkeleton = false }) => {
	const value = useOrderLineContextState({
		isSkeleton,
		response,
		setSize,
	});

	return <OrderLineContext.Provider value={value}>{children}</OrderLineContext.Provider>;
};

export const useOrderLineContext = (): UseOrderLineContextReturnType => {
	const orderLineStateContext = React.useContext(OrderLineContext);

	if (!orderLineStateContext) {
		throw new Error('useOrderLineContext must be used within a OrderLineContextProvider');
	}

	return orderLineStateContext;
};
